.dialog {
    padding: 24px;
    box-shadow: none;
    border: none;

    .dialog-header {
        display: flex;
        align-items: center;

        .dialog-title {
            color: var(--text-color);
            line-height: 24px;
        }

        .close-button {
            margin-right: -6px;
        }
    }

    .dialog-actions {
        display: flex;
        justify-content: flex-end;

        & > *:not(:last-of-type) {
            margin-right: 8px;
        }
    }
}

