.top-bar {
    display: flex;
    align-items: center;
    min-height: var(--top-bar-height);
    padding: 0 var(--page-padding);
    background-color: #231f1e;
    z-index: 100;
    top: var(--test-env-bar-height);
    left: 0;
    width: 100%;
    position: fixed;

    .link:not(:first-of-type) {
        margin-left: 0;
    }

    .logo {
        width: 28px;
        height: 28px;
        margin-right: 16px;
        display: inline-block;
    }

    .rollapps-search {
        --control-color-normal: var(--background-color-secondary);
        --control-color-hover: var(--background-color);

        margin-right: 12px;
        width: 256px;

        .clear-button {
            --button-color: rgba(var(--black-light-rgb), 0.7);
            --button-color-hover: rgba(var(--black-light-rgb), 0.7);
        }
    }

    .actions-container {
        align-items: flex-end;
    }

    .network-logo, .currency-logo {
        object-fit: cover;
        max-width: 22px;
        max-height: 22px;
        border-radius: 11px;
        margin-right: 8px;
        width: 22px;
        height: 22px;
    }

    .network-name, .asset-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
    }

    .main-currency-container, .asset-network-name {
        color: var(--text-color-secondary);
        margin-left: 6px;
        font-size: 12px;
        display: inline-flex;
        align-items: center;

        .status-indicator-container {
            display: inline-block;
            margin-left: 4px;
        }
    }

    .actions-space {
        display: none;
    }

    .main-action {
        margin-right: 12px;

        .main-action-text {
            margin-left: 4px;
        }
    }
}

@media only screen and (min-width: 1680px) {
    .rollapps-search {
        width: 320px;
        position: absolute;
        left: calc(50% - 160px);
    }
}

@media only screen and (max-width: 1280px) {
    .top-bar {
        .rollapps-search {
            margin-right: 8px;
        }

        .main-action {
            margin-right: 8px;

            .main-action-text {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .top-bar .nav-bar {
        display: none;
    }
}

@media only screen and (max-width: 720px) {
    .top-bar {
        .top-bar-middle-space, .logo {
            display: none;
        }

        .actions-space {
            display: initial;
        }
    }
}

@media only screen and (max-width: 480px) {
    .top-bar {
        flex-wrap: wrap-reverse;

        .actions-container {
            flex: 1;
        }

        .rollapps-search {
            width: 100%;
            margin: -12px 0 0 0;
        }
    }
}

