.proposal-list-header {
    margin: 24px 0 12px;
    line-height: 36px;
    align-items: center;
    display: flex;

    .proposals-controls {
        margin-left: 16px;
        display: flex;

        .proposals-filters {
            margin-right: 16px;
            width: 320px;

            .proposal-status-select {
                flex: initial;
                white-space: nowrap;
            }
        }
    }
}

.proposal-list-container {
    overflow: auto;

    .proposal-list {
        --index-column-width: 60px;

        width: 100%;

        .proposal-row {
            height: 56px;

            .proposal-name-column {
                font-size: 14px;
                overflow: hidden;
                word-break: break-word;
                display: -webkit-box;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .voting-end-time-column-content {
                display: flex;
                justify-content: flex-end;

                .date-part, .time-part {
                    white-space: nowrap;

                    &.time-part {
                        margin-left: 4px;
                    }

                    &.time-part {
                        color: var(--text-color-secondary);
                    }
                }
            }

            .column-badge {
                white-space: nowrap;
            }
        }

        .voting-end-time-column {
            padding-right: var(--page-padding);
        }
    }

    .no-data {
        display: flex;
        height: 56px;
        align-items: center;
        justify-content: center;
        color: var(--text-color-secondary);
    }
}

@media only screen and (max-width: 1024px) {
    .proposal-list-container .proposal-list .proposal-row {
        .proposal-name-column {
            min-width: 50vw;
        }

        .proposal-voting-end-time-column {
            align-items: flex-end;
            flex-direction: column;
        }
    }

    .proposal-list-header .proposals-controls {
        margin-left: 8px;

        .proposals-filters {
            margin-right: 8px;
        }

        .create-proposal-button-text {
            display: none;
        }
    }
}

@media only screen and (max-width: 720px) {
    .proposal-list-header {
        flex-direction: column;
        align-items: start;

        .proposals-controls {
            width: 100%;
            margin-left: 0;

            .proposals-filters {
                flex: 1;
            }
        }
    }

    .proposal-list-container .proposal-list {
        --index-column-width: 40px;

        font-size: 12px;

        .proposal-row .column-badge {
            white-space: initial;
        }
    }
}
