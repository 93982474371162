.snackbars-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    pointer-events: none;
    padding: 16px;
    z-index: 1000000000000;
}
