.iro-status-indicator {
    --color: var(--cream);

    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: var(--color);
    display: block;
}

.iro-status-indicator-click-area {
    padding: 6px;
}
