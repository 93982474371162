.asset-list-header {
    margin: 24px 0 12px;
    line-height: 36px;
    align-items: center;
    display: flex;
}

.asset-list-container {
    overflow: auto;

    .asset-list {
        --row-height: 64px;

        width: 100%;
    }

    .asset-name-container {
        white-space: nowrap;

        .currency-logo {
            width: 28px;
            height: 28px;
            margin-right: 8px;
            border-radius: 14px;
            object-fit: cover;
        }

        .asset-name {
            display: flex;
            flex-direction: column;
            max-width: 128px;

            .network-name {
                margin-top: 4px;
                font-size: 12px;
                color: var(--text-color-secondary);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .no-data {
        display: flex;
        height: 64px;
        align-items: center;
        justify-content: center;
        color: var(--text-color-secondary);
    }

    .incentive-apr {
        color: var(--blue);
        font-weight: 600;
        white-space: nowrap;
    }
}


@media only screen and (max-width: 720px) {
    .asset-list-header {
        flex-direction: column;
        align-items: start;
    }

    .asset-list-container {
        .asset-list {
            font-size: 12px;
        }

        .asset-name-container {
            .currency-logo {
                width: 24px;
                height: 24px;
                border-radius: 12px;
            }

            .asset-name {
                .network-name {
                    margin-left: 0;
                    margin-top: 2px;
                    max-width: 100px;
                }

                .currency-denom {
                    margin-left: 0;
                    margin-top: 6px;
                }

                .incentives-badge {
                    margin-top: 4px;
                    margin-left: 0;
                }
            }
        }
    }
}
