.area-chart-container {
    
    .chart-grid {
        stroke: rgba(var(--cream-rgb), 0.1);
    }
    
    .area-chart .recharts-area-curve {
        stroke: var(--red);
        stroke-width: 2;
    }
    
    .area-background {
        stop-color: var(--red);
    }
    
    &.positive {
        .area-background {
            stop-color: var(--green);
        }
        
        .area-chart .recharts-area-curve {
            stroke: var(--green);
        }
    }
}
