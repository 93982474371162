.edit-validator-dialog.edit-validator-dialog {
    width: 640px;

    .dialog-content {
        padding-top: var(--page-padding);

        .control-label-container label {
            font-size: 14px;
        }

        .initial-delegation-control {
            margin-bottom: 0;
        }

        .commission-error {
            margin-bottom: 0;
            margin-top: -20px;
        }

        .unsupported-wallet-message {
            margin-bottom: 16px;
        }
    }
}
