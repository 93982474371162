.statistics-card {
    align-items: center;
    display: flex;
    flex: 1;

    .property {
        flex: 1;

        .property-value {
            font-size: 16px;
        }
    }
}

.statistics-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    &.vertically {
        flex-wrap: initial;
        flex-direction: column;

        .statistics-card {
            flex: 1;
        }
    }

    .statistics-card.wide {
        //min-width: 235px;
        flex: 1 1 calc(50% - 12px);

        &.three-in-line {
            flex: 1 1 calc(33% - 12px);
        }
    }
}

.statistics-cards-swiper {
    display: none;
}

@media only screen and (max-width: 720px) {
    .statistics-cards {
        display: none;

        & + .statistics-cards-swiper {
            display: block;
        }
    }
}
