.trade-section {
    width: 540px;
    display: flex;
    flex-direction: column;

    .trade-title {
        margin-bottom: 56px;
    }

    .token-label {
        margin: -32px 0 12px;
    }

    .swap-button {
        align-self: center;
        margin: -48px 0 6px;
        z-index: 1;
    }
}

@media only screen and (max-width: 720px) {
    .trade-section {
        width: 100%;
    }
}
