$border-radius: 8px;

.alert-container {
    --color: var(--light-blue-rgb);

    padding: 16px 16px 16px 48px;
    border-radius: $border-radius;
    position: relative;
    line-height: 20px;
    color: var(--cream);
    background-color: rgba(var(--color), 0.5);

    .alert-title {
        margin: 0 0 12px;
        font-weight: bold;
        line-height: 24px;
    }

    .alert-icon-container {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 36px;
        align-items: center;
        padding: 6px;
        background-color: rgb(var(--color));
    }

    &.success {
        --color: var(--light-green-rgb);
    }

    &.warning {
        --color: var(--orange-rgb);
    }

    &.error {
        --color: var(--red-rgb);
    }
}
