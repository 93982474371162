.toggle-switch-container {
    --toggle-switch-height: 28px;
    --toggle-switch-width: 48px;
    --toggle-switch-thumb-size: 20px;
    --toggle-switch-padding: 3px;
    --label-margin: 16px;
    --toggle-thumb-color-checked: var(--cream);
    --toggle-thumb-color-unchecked: var(--cream);
    --toggle-thumb-color: var(--toggle-thumb-color-unchecked);

    display: flex;
    align-items: center;

    &.small {
        --toggle-switch-height: 20px;
        --toggle-switch-width: 34px;
        --toggle-switch-thumb-size: 14px;
        --toggle-switch-padding: 2px;
        --label-margin: 8px;
    }

    &.disabled {
        color: var(--text-color-disabled);
        cursor: default;

        .toggle-switch {
            opacity: 0.4;
        }
    }

    .toggle-switch {
        position: relative;
        display: inline-block;
        width: var(--toggle-switch-width);
        height: var(--toggle-switch-height);

        .toggle-switch-checkbox {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .toggle-switch-slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: var(--black-dark);
            border: 1px solid var(--border-color);
            transition: background-color 300ms ease-in-out;
            border-radius: var(--toggle-switch-height);

            &:before {
                position: absolute;
                content: "";
                width: var(--toggle-switch-thumb-size);
                height: var(--toggle-switch-thumb-size);
                left: var(--toggle-switch-padding);
                top: var(--toggle-switch-padding);
                background-color: var(--toggle-thumb-color);
                transition: 300ms;
                border-radius: 50%;
            }
        }

        .toggle-switch-checkbox:checked + .toggle-switch-slider {
            --toggle-thumb-color: var(--toggle-thumb-color-checked);

            background-color: var(--blue);

            &:before {
                transform: translateX(calc(var(--toggle-switch-width) - var(--toggle-switch-thumb-size) - 2px - var(--toggle-switch-padding) * 2));
            }
        }

        .toggle-switch-checkbox:focus-visible + .toggle-switch-slider {
            box-shadow: 0 0 0 3px var(--text-color-disabled);
        }

        &.have-label {
            margin-left: var(--label-margin);
        }
    }
}

