$dialog-width: 480px;

.sponsorship-vote-dialog.sponsorship-vote-dialog {
    width: $dialog-width;

    .dialog-content {
        margin-top: 24px;
    }

    .stake-button {
        margin-left: 8px;
    }

    .voting-power-label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
    }

    .vote-slider {
        appearance: none;
        width: 100%;
        border-radius: 8px;
        height: 16px;
        position: relative;
        overflow: hidden;
        outline: none;

        @mixin slider-thumb {
            width: 16px;
            height: 16px;
            border-radius: 12px;
            background-color: var(--cream);
            box-shadow: -$dialog-width 0 0 calc($dialog-width - 8px) var(--blue);
            cursor: pointer;
        }

        &::-webkit-slider-thumb {
            appearance: none;
            @include slider-thumb;
        }

        &::-moz-range-thumb {
            @include slider-thumb;
        }

        &::-webkit-slider-runnable-track {
            background-color: var(--background-color-secondary);
        }

        &::-moz-range-track {
            background-color: var(--background-color-secondary);
        }
    }

    .selected-currency-balance {
        margin-bottom: 0;
    }

    .invalid-vote-alert {
        display: flex;
        align-items: center;
        margin-top: 24px;
    }

    .vote-dialog-save-button {
        margin-top: 24px;
        margin-bottom: 16px;
        width: 100%;
    }
}
