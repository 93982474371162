.edit-accounts-dialog.edit-accounts-dialog {
    width: 1280px;
    overflow: initial;
    display: flex;
    flex-direction: column;

    .accounts-type-tab-bar {
        margin-top: calc(var(--page-padding) * -1);
        margin-left: calc(var(--page-padding) * -1);
        margin-bottom: 12px;
        width: calc(100% + var(--page-padding) * 2);
    }

    .accounts-table-header {
        margin-bottom: 12px;
        line-height: 36px;
        align-items: center;
        display: flex;

        .clear-accounts-action {
            --link-color-normal: rgba(var(--red-rgb), 0.8);
            --link-color-hover: var(--red);
            --link-color-active: var(--red);
        }

        .search-account-input {
            width: 320px;
        }
    }

    .accounts-table-container {
        max-height: 60vh;
        flex: 1;
        overflow: auto;
        border-radius: 6px 6px 0 0;
        border: 1px solid var(--border-color);

        .accounts-table {
            --row-height: 60px;

            .amount-input, .address-input {
                width: 100%;
            }

            .address-input {
                min-width: 380px;

                .address-suffix-error {
                    color: var(--red);
                    margin-left: 10px;
                    font-size: 12px;
                }
            }

            .amount-input {
                min-width: 276px;

                .base-denom-suffix {
                    margin-top: -3px;
                    font-size: 12px;
                    font-weight: bold;
                    white-space: nowrap;
                    color: var(--text-color-secondary);
                }

                .remaining-supply-button {
                    white-space: nowrap;
                }
            }

            .vesting-date-input {
                max-width: 186px;
            }

            .delete-action-column {
                width: 0;
            }

            .error-label {
                display: none;
            }
        }
    }

    .dialog-actions {
        margin-top: 24px;

        .add-account-action {
            margin-right: auto;
        }
    }

    .update-warning {
        margin-top: 24px;
    }

    .no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 0;
        color: var(--text-color-secondary);
    }
}

@media only screen and (max-width: 1024px) {
    .edit-accounts-dialog.edit-accounts-dialog {
        width: 100%;

        .accounts-table-container .accounts-table {
            .address-input {
                min-width: 328px;
            }

            .amount-input {
                min-width: 238px;
            }

            .vesting-date-input {
                max-width: 160px;
            }

            .input-container .input {
                font-size: 12px;
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .edit-accounts-dialog.edit-accounts-dialog .accounts-table-header {
        flex-direction: column;
        align-items: start;
        min-height: initial;

        .search-account-input {
            width: 100%;
        }
    }
}
