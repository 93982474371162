.tooltip {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000000000;
    white-space: initial;
    background-color: var(--background-color-secondary);
    color: var(--text-color);
    padding: 8px 12px;
    border-radius: 6px;
    max-width: 320px;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    border: 1px solid var(--border-color-dark);
    transition: opacity 150ms ease-in-out;
    text-align: left;
    opacity: 0;

    &.left {
        margin-left: -8px;
    }

    &.right {
        margin-left: 8px;
    }

    &.top {
        margin-top: -8px;
    }

    &.bottom {
        margin-top: 8px;
    }

    &.tooltip-enter {
        opacity: 0;
    }

    &.tooltip-enter-active {
        opacity: 1;
    }

    &.tooltip-enter-done {
        opacity: 1;
    }

    &.tooltip-exit {
        opacity: 1;
    }

    &.tooltip-exit-active {
        opacity: 0;
    }
}

@media (hover: none) {
    .tooltip {
        display: none;
    }
}

@media only screen and (max-width: 720px) {
    .tooltip {
        max-width: calc(100% - 16px);
    }
}
