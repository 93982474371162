.status-indicator {
    --color: var(--cream-rgb);

    width: 5px;
    height: 5px;
    border-radius: 3px;
    box-shadow: 0 0 5px 2px rgba(var(--color), 0.5);
    background-color: rgb(var(--color));
    display: block;
}

.indicator-click-area {
    padding: 6px;
}
