.resolve-transfer-dialog.resolve-transfer-dialog {
    width: 720px;

    .dialog-content {
        padding: 24px 0;
        font-size: 14px;
        color: var(--text-color);

        .list {
            color: var(--cream-dark);
        }

        .resolve-all-checkbox-label {
            font-size: 14px;
            display: block;
            margin-top: 24px;
            user-select: none;
            color: var(--text-color);
            cursor: pointer;

            .resolve-all-checkbox {
                margin-bottom: 10px;
            }
        }
    }
}
