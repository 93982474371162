.connected-wallet-menu.connected-wallet-menu {
    margin-top: 8px;
    width: 360px;
    max-height: initial;
    background-color: var(--background-color);

    .wallet-menu-header {
        padding: 8px;
        position: relative;
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--border-color);

        .wallet-logo-container {
            width: 22px;
            height: 22px;
            cursor: pointer;
            padding: 4px;
            box-sizing: content-box;
            margin-right: 6px;
            background-color: var(--black);
            border-radius: 4px;
            transition: background-color 150ms ease-in-out;

            &:hover {
                background-color: rgba(var(--cream-very-dark-rgb), 0.25);
            }

            .no-wallet svg path, .no-wallet svg g {
                fill: var(--text-color-secondary);
            }
        }

        .wallet-address {
            font-weight: 600;
        }

        .wallet-menu-actions {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: flex-end;

            .refresh-button {
                font-size: 16px;
                padding-top: 2px;
                font-weight: 500;
            }

            .quick-auth-button {

                &.connected {
                    --button-text-color: var(--orange);
                }

                &.loading {
                    --spinner-color: var(--text-color);
                    --button-text-color: rgba(var(--orange-rgb), 0.5);
                }
            }
        }
    }

    .wallet-account-tab {
        height: 320px;

        &.no-actions {
            border-top: 1px solid var(--border-color);
            height: 378px;
        }
    }

    .account-menu-actions {
        padding: 0 16px;
        height: 58px;
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        display: flex;
        align-items: center;

        .account-menu-action {
            padding: 0;
            flex: 1;

            &:not(:last-of-type) {
                margin-right: 8px;
            }
        }
    }

    .wallet-menu-nav {
        display: block;
        background-color: var(--background-color-secondary);
        border-top: 1px solid var(--border-color);

        .nav-item-list {
            margin: 0;
        }

        .wallet-menu-nav-item {
            --link-color-normal: rgba(var(--cream-dark-rgb), 0.5);

            flex: 1;
            margin: 0;
            justify-content: center;
            display: flex;
            padding: 8px 4px;
        }
    }
}

.recover-portal-wallet-menu-header {
    padding: 16px;
    width: 100%;
    line-height: 24px;
    text-align: center;
    border-bottom: 1px solid var(--border-color);

    .recover-wallet-button {
        margin-top: 16px;
    }
}

