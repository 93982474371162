.asset-statistics {
    .bonded-percentage {
        color: var(--cream-very-dark);
        margin-left: 4px;
        font-size: 14px;
    }

    .balance-property {
        flex-wrap: wrap;
        height: initial;
    }

    .rollapp-can-launch-property-value.property-value {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin: 2px 0 -4px;
        color: var(--orange);
    }
}




