.asset-dashboard-loader {
    margin: 64px auto;
}

.not-found-message {
    color: var(--text-color-secondary);
    text-align: center;
    padding: var(--page-large-padding);
}

.asset-dashboard-page.page {
    .asset-header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: var(--page-padding);

        .asset-logo {
            width: 48px;
            height: 48px;
            border-radius: 24px;
            object-fit: cover;
        }

        .asset-name {
            margin-left: 16px;
            display: flex;
            flex-direction: column;

            .asset-denom {
                color: var(--text-color-secondary);
                font-size: 14px;
                margin-top: -8px;
                padding: 0;

                &:not(.disabled):hover {
                    text-decoration: underline;
                }
            }

            .asset-display-name {
                display: flex;
                align-items: center;
            }
        }

        .inactive-badge {
            margin-left: 6px;
        }

        .asset-dashboard-actions {
            display: flex;

            .asset-dashboard-action {
                flex: 1;
                white-space: nowrap;

                &:not(:last-of-type) {
                    margin-right: 12px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1921px) {
    .asset-dashboard-page.page {
        max-width: 1920px;
    }
}

@media only screen and (max-width: 1024px) {
    .asset-dashboard-page.page .asset-header .asset-logo {
        width: 40px;
        height: 40px;
    }
}

@media only screen and (max-width: 720px) {
    .asset-dashboard-page.page {
        .asset-dashboard {
            margin-top: 20px;
        }

        .asset-header {
            .asset-logo {
                width: 36px;
                height: 36px;
            }

            .asset-name {
                margin-left: 8px;

                .asset-denom {
                    font-size: 12px;
                }
            }

            .asset-dashboard-actions {
                width: 100%;
                margin-top: 12px;
            }
        }
    }
}


