.proposal-loader {
    margin: 64px auto;
}

.not-found-proposal {
    color: var(--text-color-secondary);
    text-align: center;
}

.proposal-page {
    .type-badge-container {
        height: 40px;
        display: flex;
        align-items: center;

        .expires-in-label {
            margin-left: 16px;

            &.alert {
                color: var(--red);
            }
        }
    }

    .proposal-actions {
        position: sticky;
        z-index: 1;
        float: right;
        margin-top: calc(var(--page-small-padding) * -1);
        top: calc(var(--top-bar-height) + var(--test-env-bar-height) + var(--page-padding));
        display: flex;
        align-items: center;
    }

    .vote-button {
        --vote-color-rgb: var(--cream-rgb);
        --vote-text-color: var(--black);

        font-weight: bold;
        padding: 0 24px;
        color: var(--vote-text-color);
        box-shadow: 0 0 8px 4px rgba(var(--vote-color-rgb), 0.4);
        margin-left: 12px;
        background-color: rgba(var(--vote-color-rgb), 1);

        &:hover {
            background-color: rgba(var(--vote-color-rgb), 0.7);
        }

        &.yes {
            --vote-color-rgb: var(--dark-green-rgb);
            --vote-text-color: var(--cream);
        }

        &.no {
            --vote-color-rgb: var(--red-rgb);
            --vote-text-color: var(--cream);
        }

        &.veto {
            --vote-color-rgb: var(--orange-rgb);
            --vote-text-color: var(--cream);
        }

        &.abstain {
            --vote-color-rgb: var(--cream-very-dark-rgb);
            --vote-text-color: var(--cream);
        }

        &.disabled {
            opacity: 0.5;
        }
    }

    .proposal-title {
        line-height: initial;
        margin: 8px 0;
        word-break: break-word;
    }

    .proposal-description {
        color: var(--text-color-secondary);
        margin: var(--page-small-padding) 0 var(--page-large-padding);
    }

    .votes-summary-container {
        margin-top: var(--page-large-padding);
        padding: var(--page-large-padding) 0;
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .proposal-content-header {
        margin-top: var(--page-large-padding);
    }

    .proposal-content {
        margin-top: var(--page-padding);
        word-break: break-word;
    }
}

@media only screen and (max-width: 720px) {
    .proposal-page {
        .proposal-description {
            font-size: 14px;
        }

        .type-badge-container .expires-in-label {
            font-size: 12px;
            margin: 0 8px;
        }

        .proposal-actions {
            width: 100%;
            float: initial;
            margin: var(--page-small-padding) 0 var(--page-large-padding);

            .button {
                flex: 1;
            }
        }
    }
}
