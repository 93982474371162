.tokens-swap-dialog.tokens-swap-dialog {
    background-color: transparent;
    border: none;
    margin-top: -32px;
}

@media only screen and (max-width: 720px) {
    .tokens-swap-dialog.tokens-swap-dialog {
        padding: 0;
        width: 100%;
        overflow-x: hidden;
    }
}

