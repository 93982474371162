.text-area-container.form-control {
    height: auto;
    box-sizing: border-box;

    .text-area {
        outline: none;
        border: none;
        width: 100%;
        padding: 0;
        min-height: 100px;
        max-height: 300px;
        height: auto;
        resize: none;
        background-color: transparent;
        color: var(--control-text-color);

        &::placeholder {
            color: var(--control-placeholder-text-color);
        }
    }

    &.error {
        border-color: var(--red);
    }

    &:not(.disabled) .text-area:not(:placeholder-shown) {
        --control-text-color: var(--text-color);
    }
}

