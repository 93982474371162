$section-width: 540px;

.ibc-transfer-section {
    width: $section-width;
    display: flex;
    flex-direction: column;

    .ibc-transfer-title {
        margin-bottom: 56px;
    }

    .swap-button {
        align-self: center;
        margin: 6px 0;
    }

    .network-selectors-container {
        position: relative;

        .network-label {
            position: absolute;
            bottom: calc(100% + 12px);
        }
    }

    .skip-link {
        font-weight: bold;
        text-decoration: underline;
    }

    .amount-label {
        margin: 32px 0 12px;
    }

    .eibc-transfer {
        max-width: 40px;
        box-shadow: 0 0 4px 0 var(--black-dark);
        transform: scale(1.05);
    }

    .bridging-fee {
        display: flex;
        align-items: center;
        margin: -7px 0;
    }

    .eibc-switch {
        margin: 12px 12px 0;
    }

    .eibc-fee-input {
        --control-height: 28px;
        --control-padding: 6px;

        background-color: transparent;
        width: 72px;
    }

    .deposit-withdraw-tab-bar {
        margin-top: calc(var(--page-padding) * -1);
        margin-left: calc(var(--page-padding) * -1);
        width: calc(100% + var(--page-padding) * 2);

        .deposit-withdraw-tab-label {
            display: flex;
            align-items: center;
            justify-content: center;

            .deposit-withdraw-icon {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }

        &:not(.history) {
            margin-bottom: calc(var(--page-padding) + 32px);
        }
    }

    &.wide {
        width: 640px;
    }
}

.get-tokens-section {
    width: $section-width;
    margin-top: 32px;
}

.finalization-text {
    max-width: 420px;
}

.import-to-wallet-button {
    padding: 4px 8px;
    margin-top: 8px;
    align-self: self-start;
}

.ibc-alert {
    margin-bottom: 24px;
    margin-top: -8px;
}

.rollapp-params-spinner {
    --spinner-color: var(--text-color);

    margin-bottom: -2px;
    display: inline-block;
}

@media only screen and (max-width: 720px) {
    .ibc-transfer-section {
        font-size: 12px;

        &, &.wide {
            width: 100%;
        }

        .deposit-withdraw-tab-bar .deposit-withdraw-tab-label .deposit-withdraw-icon {
            width: 20px;
            height: 20px;
        }
    }

    .get-tokens-section {
        width: 100%;
        margin-top: 16px;
    }
}
