.proposal-json-item.jse-theme-dark {
    --jse-active-line-background-color: transparent;
    --jse-main-border: 1px solid var(--border-color);
    --jse-background-color: var(--black-dark);
    --jse-hover-background-color: rgba(var(--black-dark-rgb), 0.5);

    .cm-gutters, .jse-message.jse-error {
        display: none;
    }

    .jse-main {
        min-height: 80px;

        .jse-text-mode, .jse-contents {
            border-radius: 4px;

            &:hover {
                --jse-background-color: var(--jse-hover-background-color);
            }
        }
    }
}
