.bridge-status-badge {
    white-space: nowrap;

    .status-spinner {
        --spinner-color: var(--orange);

        margin-right: 6px;
        animation-duration: 1.2s;
    }
}
