.market-cap-property {
    .mc-warning-icon {
        --color: var(--orange);

        width: 15px;
        height: 15px;
        margin-right: 4px;
    }

    &.invalid {
        font-style: italic;
    }
}
