.asset-dashboard-loader {
    margin: 64px auto;
}

.not-found-message {
    color: var(--text-color-secondary);
    text-align: center;
    padding: var(--page-large-padding);
}

