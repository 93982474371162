.network-summary {
    display: grid;
    grid-template-columns: 320px auto 320px;
    grid-template-rows: auto auto auto;
    gap: var(--page-small-padding);

    .network-statistics-cards {
        grid-column: 1;
        grid-row: 1;
    }

    .network-chart {
        grid-column: 2;
        grid-row: 1;
    }

    .network-info-section {
        grid-column: 1;
        grid-row: 2;
    }

    .sequencer-info-section {
        grid-column: 1;
        grid-row: 3;
    }

    .network-middle-section {
        grid-column: 2;
        grid-row: 2 / span 2;
    }

    .network-community {
        grid-column: 3;
        grid-row: 1 / span 3;

        .community-title {
            display: none;
        }

        .network-sponsorship {
            margin-bottom: 16px;
        }
    }

    &.hub {
        grid-template-columns: 320px auto;
    }

    &.no-sequencer .network-middle-section {
        grid-row: 2;
    }
}

@media only screen and (max-width: 1280px) {
    .network-summary {
        grid-template-columns: auto 320px;
        grid-template-rows: auto auto auto auto auto;

        .network-chart {
            margin-top: var(--page-small-padding);
            grid-column: 1;
            grid-row: 2;
        }

        .network-middle-section.network-middle-section {
            grid-column: 1;
            grid-row: 3;
        }

        .network-info-section {
            grid-column: 1;
            grid-row: 4;
        }

        .sequencer-info-section {
            grid-column: 1;
            grid-row: 5;
        }

        .network-community {
            grid-column: 2;
            grid-row: 1 / span 5;
        }

        &.hub {
            grid-template-columns: auto;
            grid-template-rows: auto auto auto auto;

            .network-info-section {
                grid-row: 3;
            }

            .network-middle-section {
                grid-row: 4;
            }

        }
    }
}

@media only screen and (max-width: 1024px) {
    .network-summary {
        grid-template-rows: auto auto auto auto auto auto;

        .network-community {
            grid-column: 1;
            grid-row: 6;

            .community-title {
                display: flex;
            }
        }

        &, &.hub {
            grid-template-columns: 100%;
        }
    }
}


@media only screen and (max-width: 720px) {
    .network-summary .network-chart {
        margin-top: 0;
    }
}
