$dialog-width: 540px;

.asset-bond-dialog.asset-bond-dialog {
    width: $dialog-width;
    display: flex;
    flex-direction: column;

    .dialog-content {
        margin-top: 24px;
    }

    .submit-button {
        width: 100%;
    }

    .position-header {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        margin-bottom: 32px;

        .position-label {
            color: var(--text-color-secondary);
        }
    }

    .bond-amount-slider {
        appearance: none;
        width: 100%;
        border-radius: 8px;
        height: 16px;
        position: relative;
        overflow: hidden;
        outline: none;

        @mixin slider-thumb {
            width: 16px;
            height: 16px;
            border-radius: 12px;
            background-color: var(--cream);
            box-shadow: -$dialog-width 0 0 calc($dialog-width - 8px) var(--blue);
            cursor: pointer;
        }

        &::-webkit-slider-thumb {
            appearance: none;
            @include slider-thumb;
        }

        &::-moz-range-thumb {
            @include slider-thumb;
        }

        &::-webkit-slider-runnable-track {
            background-color: var(--background-color-secondary);
        }

        &::-moz-range-track {
            background-color: var(--background-color-secondary);
        }
    }

    .amount-part-buttons {
        display: flex;
        justify-content: space-between;
        margin: 24px 0 32px;

        & > * {
            flex: 1;

            &:not(:last-of-type) {
                margin-right: 24px;
            }
        }
    }
}
