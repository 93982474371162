.deposit-withdraw-dialog.deposit-withdraw-dialog {
    background-color: transparent;
    border: none;
    margin-top: -32px;
}

@media only screen and (max-width: 720px) {
    .deposit-withdraw-dialog.deposit-withdraw-dialog {
        padding: 0;
        width: 100%;
        overflow-x: hidden;

        .ibc-transfer-section {
            border-radius: 6px;
        }
    }
}

