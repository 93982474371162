.init-quick-auth-wallet-dialog.init-quick-auth-wallet-dialog {
    width: 480px;

    .dialog-title {
        display: flex;
        align-items: center;

        .wallet-logo {
            display: block;
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }
    }

    .dialog-content {
        margin-top: 16px;
    }

    .qr-scanner-container {
        margin-top: var(--page-padding);
        width: 100%;
        aspect-ratio: 1 / 1;
    }

    .pin-code-section {
        .pin-code-label {
            text-align: center;
        }

        .pin-code-characters {
            margin-top: 16px;
            display: flex;
            justify-content: center;
        }

        .pin-code-character-input {
            font-family: monospace;
            font-size: 20px;
            text-align: center;
            width: 45px;
            height: 60px;

            input.input {
                text-align: center;
            }

            &:not(:last-of-type) {
                margin-right: 8px;
            }
        }
    }

    .confirm-button {
        margin-top: 16px;
        width: 100%;
    }
}

