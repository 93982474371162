.wallet-address-container {
    display: flex;
    align-items: center;

    .wallet-logo {
        margin-right: 8px;
        height: 20px;
        width: 20px;
        display: block;
    }
}
