.ibc-transfer-list-header {
    margin: 24px 0 12px;
    line-height: 36px;
    align-items: center;
    flex-wrap: wrap;
    display: flex;

    .header-label {
        display: flex;
        margin-right: 16px;
        align-items: center;

        .show-all-switch {
            --toggle-thumb-color-unchecked: var(--text-color-secondary);

            margin-left: 8px;
            font-size: 14px;
            color: var(--text-color-secondary);
        }

        .refresh-button {
            font-weight: bold;
        }
    }

    .transfers-controls {
        display: flex;
        min-width: 504px;

        .transfers-filters {
            width: 100%;

            .transfer-status-select {
                flex: initial;
                white-space: nowrap;
            }
        }
    }
}

.ibc-transfer-list-container {
    overflow: auto;

    .ibc-transfer-list {
        --index-column-width: 100px;

        width: 100%;
        font-size: 14px;

        .ibc-transfer-row {
            height: 64px;

            .transaction-hash-column .hash {
                &.clickable {
                    font-weight: 600;
                }

                &:not(.clickable) {
                    color: var(--text-color-secondary);
                }
            }

            .network-address-column-content {

                .network-logo {
                    object-fit: cover;
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                    border-radius: 12px;
                }

                .name-address {
                    white-space: nowrap;
                    display: flex;
                    align-items: center;

                    .address {
                        color: var(--text-color-secondary);
                        font-size: 12px;
                        margin-left: 6px;
                        display: flex;
                        align-items: center;
                    }
                }
            }

            .amount-column {
                .asset-logo {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                    border-radius: 12px;
                    object-fit: cover;
                }

                .no-fee-label {
                    color: var(--red);
                    margin-left: 4px;
                    font-weight: bold;
                    font-size: 12px;
                }
            }

            .time-column {
                white-space: nowrap;
            }
        }
    }

    .no-data {
        display: flex;
        height: 64px;
        align-items: center;
        justify-content: center;
        color: var(--text-color-secondary);
    }
}

@media only screen and (max-width: 1024px) {
    .ibc-transfer-list-header .transfers-controls {
        flex: 1;
    }
}

@media only screen and (max-width: 720px) {
    .ibc-transfer-list-header .transfers-controls {
        min-width: 100%;
    }

    .ibc-transfer-list-container {
        .ibc-transfer-list {
            --index-column-width: 80px;

            font-size: 12px;

            .ibc-transfer-row .network-address-column-content .name-address {
                flex-direction: column;
                align-items: flex-start;

                .address {
                    margin-left: 0;
                }
            }
        }
    }
}


