.path-nav {
    display: flex;
    margin: calc(var(--page-padding) * -1) 0 var(--page-padding);
}

.path-nav-item {
    padding: 0;
    line-height: initial;

    &:last-of-type {
        color: var(--text-color);
    }

    &:not(.disabled):hover {
        text-decoration: underline;
    }

    &:not(:first-of-type) {
        margin-left: 24px;
    }

    &:not(:last-of-type) {
        color: var(--text-color-secondary);

        &:after {
            position: absolute;
            content: '/';
            right: -15px;
        }
    }
}

@media only screen and (max-width: 720px) {
    .path-nav {
        font-size: 12px;
    }

    .path-nav-item {
        &:not(:first-of-type) {
            margin-left: 18px;
        }

        &:not(:last-of-type):after {
            right: -13px;
        }
    }
}
