.app-layout {
    --test-env-bar-height: 0px;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    main {
        flex: 1;
        padding-top: calc(var(--top-bar-height) + var(--test-env-bar-height));
    }

    .test-env-bar {
        display: none;
        width: 100%;
        min-height: var(--test-env-bar-height);
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        z-index: 101;
        text-align: center;
        font-weight: bold;
        color: white;
        background-color: var(--orange);
    }

    .app-loader {
        margin: 64px auto;
    }

    &.test-env {
        --test-env-bar-height: 24px;

        .test-env-bar {
            display: flex;
        }
    }
}

.phantom-alert-dialog.phantom-alert-dialog {
    width: 824px;

    .dialog-title {
        color: var(--red);
        display: flex;
        align-items: center;

        .icon-container {
            --color: var(--red);

            margin-right: 8px;
            width: 36px;
            height: 36px;
        }
    }

    .dialog-content {
        margin-top: var(--page-padding);
    }
}
