.asset-dashboard {
    display: grid;
    gap: var(--page-small-padding);
    grid-template-columns: 320px calc(100% - 640px - (var(--page-small-padding) * 2)) 320px;
    grid-template-rows: auto auto auto;

    .asset-statistics {
        grid-row: 1;
        grid-column: 1;
    }

    .asset-price-chart {
        grid-row: 1;
        grid-column: 2;
    }

    .trading-section {
        grid-column: 3;
        grid-row: 1 / span 3;

        .post-comment-section {
            padding-bottom: calc(var(--page-small-padding) + 24px);
        }

        .buy-sell-section {
            margin-bottom: 16px;

            .unavailable-reason {
                color: rgba(var(--cream-rgb), 0.7);
                margin-top: 2px;
                display: block;
            }
        }

        .trading-title {
            display: none;
        }
    }

    .asset-info-section {
        grid-row: 2;
        grid-column: 1;
    }

    .asset-middle-section {
        grid-row: 2 / span 3;
        grid-column: 2;

        .bonding-curve-section {
            margin-bottom: var(--page-small-padding);
        }

        .asset-data-lists-section {
            .tab-action-list {
                margin: 8px 0;

                h6 {
                    font-size: 16px;
                }
            }

            .tab {
                min-height: 420px;
            }
        }
    }

    .subtitle {
        margin: 8px 0;
        align-items: center;
        height: 40px;
        display: flex;
    }

    .scrolling-trade-pairs {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10;
    }
}

@media only screen and (max-width: 1440px) {
    .asset-dashboard {
        grid-template-columns: calc(100% - 320px - var(--page-small-padding)) 320px;
        grid-template-rows: auto auto auto auto;

        .asset-price-chart {
            grid-row: 2;
            grid-column: 1;
            margin-top: var(--page-small-padding);
        }

        .trading-section {
            grid-column: 2;
            grid-row: 1 / span 4;
        }

        .asset-middle-section {
            grid-row: 3;
            grid-column: 1;

            .asset-data-lists-section .tab {
                min-height: 0;
            }
        }

        .asset-info-section {
            grid-row: 4;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .asset-dashboard.asset-dashboard {
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto auto auto auto;

        .asset-price-chart {
            grid-row: 2;
            grid-column: 1;
            margin-top: var(--page-small-padding);
        }

        .trading-section {
            grid-column: 1;
            grid-row: 3;

            .trading-title {
                display: flex;
            }

            .buy-sell-section {
                margin-bottom: 0
            }
        }

        .asset-middle-section {
            grid-row: 4;
            grid-column: 1;
        }


        .asset-info-section {
            grid-row: 5;
        }

        .comments-section {
            grid-row: 6;
        }

        &.no-comments {
            grid-template-rows: auto auto auto auto auto;
        }
    }
}

.asset-dashboard-cta {
    margin-bottom: 24px;
}

@media only screen and (min-width: 1025px) {
    .asset-dashboard-footer {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .asset-dashboard {
        padding-bottom: calc(var(--page-padding) + 24px);

        .scrolling-trade-pairs {
            bottom: var(--footer-height);
        }

        .asset-middle-section .asset-data-lists-section .tab-action-list h6 {
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 720px) {
    .asset-dashboard .asset-price-chart {
        margin-top: 0;
    }
}

