.pool-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .denom-name:hover {
        text-decoration: underline;
    }

    .asset-logo {
        object-fit: cover;
        width: 48px;
        height: 48px;
        border-radius: 24px;
        z-index: 2;

        &:last-of-type {
            z-index: 1;
            margin-left: -16px;
        }
    }

    .pool-name {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        flex: 1;

        .pool-number {
            color: var(--text-color-secondary);
            font-size: 14px;
            margin-top: -8px;
        }

        .pool-asset-names {
            display: flex;
            align-items: center;
        }
    }

    .pool-dashboard-actions {
        display: flex;

        .pool-dashboard-action {
            flex: 1;
            white-space: nowrap;

            &:not(:last-of-type) {
                margin-right: 12px;
            }
        }
    }
}

.bonding-warning {
    margin-top: 24px;
}

.pool-network-inactive-badge {
    margin-top: 16px;
}

.pool-statistics {
    margin-top: var(--page-padding);
}

@media only screen and (max-width: 1024px) {
    .pool-header .asset-logo {
        width: 40px;
        height: 40px;
    }
}

@media only screen and (max-width: 720px) {
    .pool-header {
        .asset-logo {
            width: 36px;
            height: 36px;
        }

        .pool-name {
            margin-left: 8px;

            .pool-number {
                font-size: 12px;
            }
        }

        .pool-dashboard-actions {
            width: 100%;
            margin-top: 12px;
        }
    }

    .pool-statistics {
        margin-top: 20px;
    }
}
