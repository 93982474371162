.pin-code-dialog.pin-code-dialog {
    width: 480px;

    .dialog-content {
        margin-top: 16px;
        align-items: center;
        display: flex;
        flex-direction: column;

        .pin-code {
            margin-top: 32px;
            width: 100%;
            display: flex;
            margin-bottom: 16px;

            .pin-character {
                flex: 1;
                background-color: var(--black-dark);
                text-align: center;
                padding: 24px 0;
                font-size: 24px;
                border: 1px solid var(--border-color);
                font-family: monospace;
                border-radius: 8px;

                &:not(:last-of-type) {
                    margin-right: 8px;
                }
            }
        }

        .description {
            margin-top: 32px;
        }
    }

    .complete-action {
        width: 100%;
        margin-top: 48px;
    }
}

@media only screen and (max-width: 720px) {
    .pin-code-dialog.pin-code-dialog .dialog-content .pin-code .pin-character {
        padding: 16px 0;
    }
}
