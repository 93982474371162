.nav-bar .nav-item-list {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;

    .nav-bar-item {
        --link-color-normal: var(--text-color-secondary);
        --link-color-hover: var(--cream-dark);
    }

    .arrow-down {
        width: 13px;
        height: 7.5px;
        margin-left: 6px;
    }
}
