.ibc-status-badge {
    white-space: nowrap;

    .status-spinner {
        --spinner-color: var(--badge-color);

        margin-right: 6px;
        animation-duration: 1.2s;
    }

}
