.chart-data-value {
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    
    .data-value-type-indicator {
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 4px;
        margin-right: 6px;
        background-color: var(--indicator-color);
    }
    
    .data-value-type {
        margin-right: 4px;
    }
    
    .data-value {
        font-weight: bold;
        margin-right: 4px;
    }
}
