.incentives-badge {
    --badge-color: var(--light-green-rgb);

    margin-left: 8px;
    font-weight: 600;

    &.upcoming {
        --badge-color: var(--orange-rgb);
    }
}

.dot-badge {
    --badge-color: var(--light-green-rgb);

    width: 16px;
    height: 16px;
    position: relative;
    outline: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;

    &:after {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        left: 5px;
        top: 5px;
        border-radius: 6px;
        background-color: rgb(var(--badge-color));
    }

    &.upcoming {
        --badge-color: var(--orange-rgb);
    }
}

.tooltip-content {
    padding-bottom: 8px;

    .border {
        margin-top: 16px;
        padding-top: 12px;
        display: block;
        border-top: 1px solid var(--border-color)
    }

    .incentive-coins-list {
        list-style: none;
        margin: 0;
        padding: 0;

        .coins {
            margin-top: 12px;
            display: flex;
            align-items: center;

            .amount {
                flex: 1;
                text-align: right;
                margin-left: 24px;
                font-weight: bold;
            }

            .currency-logo {
                object-fit: cover;
                width: 16px;
                height: 16px;
                margin-right: 8px;
                border-radius: 8px;
            }
        }

        .start-time, .end-time {
            color: var(--text-color-secondary);
        }
    }
}

.tooltip-container {
    max-width: initial;
}
