.transfer-confirmation-dialog.transfer-confirmation-dialog {
    width: 640px;

    .dialog-content {
        margin: 16px 0;
        color: var(--cream-dark);
        line-height: 24px;

        .signatures-label {
            margin-top: 12px;
            font-weight: bold;
            color: var(--text-color);
        }

        .networks-route {
            margin: 24px 0;
            display: flex;
            align-items: center;

            .network-logo {
                min-width: 64px;
                min-height: 64px;
                padding: 8px;
                border-radius: 32px;
                border: 2px solid rgba(var(--light-blue-rgb), 0.5);
                background-color: rgba(var(--light-blue-rgb), 0.15);
                object-fit: cover;
                margin-right: 0;
            }

            .transfer-line {
                flex: 1;
                display: flex;
                height: 2px;
                background-color: rgba(var(--light-blue-rgb), 0.5);
                align-items: center;
                justify-content: center;

                .transfer-name {
                    background-color: rgba(var(--black-rgb), 0.7);
                    padding: 0 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    font-weight: bold;
                    color: var(--text-color);

                    .transfer-logo {
                        margin-right: 4px;
                        display: block;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .transfer-confirmation-dialog.transfer-confirmation-dialog .dialog-content .networks-route .network-logo {
        min-width: 40px;
        min-height: 40px;
        padding: 4px;
        border-radius: 20px;
    }
}
