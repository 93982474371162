.duration-trigger.form-control {
    color: var(--text-color);
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;

    .timelapse-icon {
        --color: var(--text-color-secondary);

        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
    }

    &:disabled {
        cursor: initial;
        color: var(--text-color-disabled);

        .timelapse-icon {
            --color: var(--text-color-disabled);
        }
    }
}

.duration-modal {
    margin: 4px 0;
    display: flex;
    flex-direction: column;
    min-width: initial !important;

    .time-unit-labels {
        display: flex;
        align-self: center;
        width: 100%;
        margin-bottom: 12px;

        .unit-label {
            flex: 1;
            text-align: center;
        }
    }

    .time-unit-selectors {
        display: flex;
    }

    .time-unit-selector-container {
        display: flex;
        flex-direction: column;
        overflow: auto;
        min-width: 60px;
        flex: 1;
        -ms-overflow-style: none;
        scrollbar-width: none;

        .unit-value {
            display: block;
            padding: 0 8px;
            cursor: pointer;
            background-color: transparent;
            border: none;

            &.active {
                background-color: rgba(var(--light-blue-rgb), 0.7);
                font-weight: bold;
            }

            &:disabled {
                color: var(--text-color-disabled);
                cursor: initial;
            }

            &:not(.active):not(:disabled):hover {
                background-color: var(--black-light);
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }

        &:not(:last-of-type) {
            margin-right: 8px;
        }
    }
}
