$card-background-color: rgba(var(--cream-very-dark-rgb), 0.1);

.votes-summary {
    width: 100%;

    .summary-header {
        display: flex;
        align-items: center;
        margin-bottom: var(--page-padding);

        .quorum-not-met-badge {
            margin-right: 8px;
        }
    }

    .vote-options-container {
        display: flex;
        margin: -8px;

        .vote-option {
            height: 110px;
            padding: 16px;
            margin: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            background-color: $card-background-color;
            border-radius: 6px;

            .option-name {
                font-size: 20px;
                font-weight: bold;
                display: flex;
                color: rgba(var(--vote-color-rgb), 1);
            }

            .option-amount {
                font-size: 18px;
                font-weight: 600;
            }

            .votes-amount {
                font-size: 14px;
                color: var(--text-color-secondary);
                font-weight: 400;
                margin-top: 4px;
            }

            &.most-answered {
                border: 1px solid rgba(var(--vote-color-rgb), 1);
                background-color: rgba(var(--vote-color-rgb), 0.05);
            }

            &.yes {
                --vote-color-rgb: var(--dark-green-rgb);
            }

            &.no {
                --vote-color-rgb: var(--red-rgb);
            }

            &.veto {
                --vote-color-rgb: var(--orange-rgb);
            }

            &.abstain {
                --vote-color-rgb: var(--cream-very-dark-rgb);
            }
        }
    }

    .quorum-header {
        display: flex;
        align-items: center;
        margin: var(--page-padding) 0 12px;
    }

    .quorum-progress {
        position: relative;
        height: 24px;

        .quorum-trace {
            overflow: hidden;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            background-color: $card-background-color;
            border-radius: 6px;

            .turnout-bar {
                display: block;
                height: 100%;
                width: 66%;
                background-color: red;
                background-color: rgba(var(--red-rgb), 0.25);

                &.success {
                    background-color: rgba(var(--dark-green-rgb), 0.25);
                }
            }

            .turnout-value {
                position: absolute;
                width: 100%;
                text-align: center;
                font-weight: bold;
            }

            .quorum-loader {
                margin: 0 auto;
            }
        }

        .quorum-value {
            left: 33.4%;
            position: absolute;
            width: 100px;
            color: rgba(var(--cream-rgb), 0.33);
            font-weight: bold;
            padding-top: 8px;
            font-size: 12px;
            height: 100%;
            margin-left: -50px;
            text-align: center;
            top: 100%;

            &:before {
                content: '';
                height: calc(100% + 6px);
                width: 2px;
                top: calc(-100% - 3px);
                left: calc(50% - 1px);
                position: absolute;
                background-color: rgba(var(--cream-rgb), 0.25);
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .votes-summary .vote-options-container {
        flex-wrap: wrap;

        .vote-option {
            width: calc(50% - 16px);
            flex: initial;
        }
    }
}

@media only screen and (max-width: 720px) {
    .votes-summary .vote-options-container .vote-option {
        height: 100px;

        .option-name {
            font-size: 18px;
        }

        .option-amount {
            font-size: 14px;
        }

        .votes-amount {
            font-size: 12px;
        }
    }
}

