.validators-list-header.total {

    .validators-controls {
        margin-left: 16px;
        display: flex;

        .validators-filters {
            width: 360px;

            .validators-status-select {
                flex: initial;
                white-space: nowrap;
            }
        }

        .create-validator-button {
            margin-left: 16px;
        }
    }

    &.simple-mode {
        font-size: 14px;
    }
}

.validator-status-loader {
    margin-left: 8px;
}

@media only screen and (max-width: 1024px) {
    .validators-list-header.total .validators-controls {
        margin-left: 8px;

        .create-validator-button {
            margin-left: 8px;

            .create-validator-button-text {
                display: none;
            }
        }
    }
}


@media only screen and (max-width: 720px) {
    .validators-list-header.total {
        margin-top: 24px;
        flex-direction: column;
        align-items: start;

        .validators-controls {
            width: 100%;
            margin-left: 0;

            .validators-filters {
                flex: 1;
            }
        }
    }
}
