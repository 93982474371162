.account-stake {
    list-style: none;
    margin: 0;
    overflow: auto;
    padding: 8px 0 40px;

    .delegations-loader {
        position: absolute;
        left: calc(50% - 12px);
        z-index: 1;
    }

    .new-stake-button {
        display: flex;
        align-items: center;
        margin: 8px auto;
    }

    .delegation-row {
        display: flex;
        height: 48px;
        align-items: center;
        padding: 0 12px;
        cursor: pointer;

        .validator-logo {
            width: 24px;
            height: 24px;
            min-width: 24px;
            border-radius: 12px;
            overflow: hidden;
            object-fit: cover;
            margin-right: 8px;

            &.fallback {
                background-color: var(--cream);
                padding: 4px;
            }
        }

        .validator-name-container {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            font-weight: 600;
            flex: 1;
            width: 0;

            .validator-network {
                font-weight: 400;
                font-size: 12px;
                margin-top: 2px;
                color: var(--text-color-secondary);
                display: block;
                line-height: 12px;
            }
        }

        .delegation-amount {
            display: block;
            flex-direction: column;
            text-align: right;
            font-weight: 600;
            margin-left: 8px;
            font-size: 14px;
            white-space: nowrap;
        }

        .delegation-value {
            font-size: 12px;
            margin-top: 2px;
            color: var(--text-color-secondary);
            display: block;
            line-height: 12px;
        }

        &:hover {
            background-color: rgba(var(--cream-rgb), 0.05);
        }
    }

    .visible-networks-action {
        margin-top: -40px;
        background-color: rgba(var(--black-rgb), 0.8);
        font-weight: bold;
        justify-content: center;
    }

    .endpoints-warning {
        margin: 16px 8px 0;
        padding: 8px 12px 8px 12px;

        ul.list {
            margin-top: -2px;
        }

        .alert-icon-container {
            display: none;
        }
    }
}

