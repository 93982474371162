.vote-dialog.vote-dialog {
    width: 560px;

    .vote-proposal-title {
        margin-top: 8px;
        margin-bottom: 24px;
        color: var(--text-color-secondary);
    }

    .dialog-vote-options-container {
        display: flex;
        margin: 24px -4px 0;

        .dialog-vote-option {
            margin: 4px;
            flex: 1;
            line-height: 60px;
            border-radius: 8px;
            font-size: 18px;
            text-align: center;
            height: 60px;
            border: 1px solid rgba(var(--vote-color-rgb), 0.2);
            color: rgba(var(--vote-color-rgb), 0.7);
            background-color: rgba(var(--vote-color-rgb), 0.1);
            transition: all 200ms ease-in-out;
            opacity: 0.5;

            &:not(:disabled) {
                opacity: 1;
                cursor: pointer;

                &:hover {
                    border: 1px solid rgba(var(--vote-color-rgb), 0.4);
                    color: rgba(var(--vote-color-rgb), 1);
                    background-color: rgba(var(--vote-color-rgb), 0.2);
                }

                &.active {
                    outline: 1px solid rgba(var(--vote-color-rgb), 1);
                    border: 1px solid rgba(var(--vote-color-rgb), 1);
                    color: rgba(var(--vote-color-rgb), 1);
                }
            }

            &.yes {
                --vote-color-rgb: var(--dark-green-rgb);
            }

            &.no {
                --vote-color-rgb: var(--red-rgb);
            }

            &.veto {
                --vote-color-rgb: var(--orange-rgb);
            }

            &.abstain {
                --vote-color-rgb: var(--cream-very-dark-rgb);
            }
        }
    }

    .vote-dialog-confirm-button {
        margin-top: 24px;
        width: 100%;
    }

    .can-vote-loader {
        margin: 0 auto;
    }

    .transaction-fee {
        margin-top: 24px;
        color: var(--text-color-secondary);
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .transaction-fee-value {
            font-weight: 600;
        }
    }
}
