.property {
    display: flex;
    flex-direction: column;

    .property-label {
        color: var(--text-color-secondary);
        font-size: 12px;
        display: flex;
        align-items: center;

        &.have-info {
            margin: 0;

            .column-info-indicator {
                margin: -6px 0;
            }
        }
    }

    .property-value {
        font-size: 14px;
        font-weight: 600;
        margin-top: 6px;
        display: flex;
        min-height: 20px;
        align-items: center;

        .property-value-suffix {
            font-size: 12px;
            font-weight: 500;
            margin: 2px 0;
        }
    }

    &.centered {
        align-items: center;
    }

    &.small {
        .property-label {
            font-size: 11px;
        }

        .property-value {
            font-size: 12px;
            margin-top: 2px;
        }
    }
}

@media only screen and (max-width: 720px) {
    // workaround for some chromium bug
    .property .property-value {
        padding-bottom: 10px;
        margin-bottom: -10px;
        height: auto;

        .spinner {
            margin: 2px 0;
        }
    }
}
