.networks-selector-dialog.networks-selector-dialog {
    width: 640px;
    height: 640px;
    display: flex;
    flex-direction: column;

    .dialog-content {
        margin: 24px 0;
        flex: 1;
        height: 0;

        .options-modal {
            box-shadow: none;
            border-radius: 0;
        }
    }
}
