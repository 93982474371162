.table {
    --column-horizontal-padding: 10px;
    --column-vertical-padding: 4px;
    --row-height: 42px;
    --row-header-heght: 34px;
    --index-column-width: 50px;

    width: 100%;
    border-collapse: collapse;
    background-color: var(--background-color);
    border-radius: 6px 6px 0 0;

    .table-row {
        height: var(--row-height);
        text-align: left;

        .table-column {
            position: relative;
            padding: var(--column-vertical-padding) var(--column-horizontal-padding);
            user-select: none;

            .column-content {
                display: flex;
                align-items: center;

                .info-indicator {
                    margin: calc(var(--column-vertical-padding) * -1) -12px calc(var(--column-vertical-padding) * -1) 0;
                }

                &.right {
                    justify-content: flex-end;
                    text-align: right;
                    padding-right: 4px;

                    .sort-arrow {
                        margin-right: 6px;
                        margin-left: 0;
                    }
                }

                &.center {
                    justify-content: center;
                    text-align: center;
                    padding-right: 4px;
                }
            }

            &.index-column {
                width: var(--index-column-width);

                .index-column-content {
                    width: var(--index-column-width);
                    margin: 0 calc(var(--column-horizontal-padding) * -1);
                    justify-content: center;
                    color: var(--text-color-secondary);
                }
            }

            &.sticky {
                position: sticky;
                z-index: 2;
                left: 0;

                &.haveIndex {
                    left: var(--index-column-width);
                }
            }

            &:last-of-type .column-content.right {
                padding-right: 0;
            }
        }

        &.header {
            background-color: var(--background-color-secondary);
            height: var(--row-header-heght);
            font-size: 12px;
            color: var(--text-color-secondary);

            .table-column {
                font-weight: 500;

                .column-container.right {
                    text-align: right;
                }

                .column-container.center {
                    text-align: center;
                }

                .sort-arrow {
                    width: 10px;
                    height: 10px;
                    display: inline-block;
                    vertical-align: text-top;
                    margin-left: 4px;
                    transition: all 200ms ease-in-out;
                    opacity: 0;

                    svg path, svg g {
                        stroke: var(--text-color);
                    }
                }

                &.sortable {
                    cursor: pointer;

                    &.desc .sort-arrow {
                        transform: rotate(180deg);
                    }

                    &:hover .sort-arrow {
                        opacity: 0.5;
                    }

                    &.asc, &.desc {
                        .sort-arrow {
                            opacity: 1;
                        }
                    }
                }

                &:first-of-type {
                    border-top-left-radius: 6px;
                }

                &:last-of-type {
                    border-top-right-radius: 6px;
                }

                &.sticky {
                    background-color: var(--background-color-secondary);
                }
            }
        }

        &:not(.header) {
            border-bottom: 1px solid var(--border-color);
            cursor: pointer;

            .table-column.sticky {
                background-color: var(--background-color);
            }

            &:hover {
                &, .table-column.sticky {
                    background-color: #292524;
                }
            }
        }
    }

    &.fixed-layout {
        table-layout: fixed;
    }

    &.header-sticky .table-row.header {
        position: sticky;
        top: 0;
        z-index: 10;
    }
}

.bottom-bar-container {
    background-color: var(--background-color);
    padding: var(--column-vertical-padding) var(--column-horizontal-padding);
    border-bottom: 1px solid var(--border-color);
}

@media only screen and (max-width: 1024px) {
    .table {
        --index-column-width: 40px;
        --column-horizontal-padding: 6px;

        border-radius: 6px 6px 0 0;
    }
}


@media only screen and (max-width: 720px) {
    .table {
        --index-column-width: 30px;
        --column-horizontal-padding: 4px;

        .index-column-content {
            font-size: 12px;
        }
    }
}


@media (hover: none) {
    .table .info-indicator {
        display: none;
    }
}



