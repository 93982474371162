.chart-tooltip {
    background-color: var(--background-color-secondary);
    border-radius: 6px;
    padding: 8px 12px 12px;
    border: 1px solid var(--border-color-dark);

    .tooltip-label {
        color: var(--text-color-secondary);
    }

    .chart-tooltip-data-value {
        margin-top: 12px;
    }

    &.simple {
        font-size: 12px;
        font-weight: 400;
    }
}


