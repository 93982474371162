.withdraw-property {
    position: relative;
    justify-content: center;

    .withdraw-button {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
    }
}
