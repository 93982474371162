.eibc-client-statistics {
    .property-value.orders-property-value {
        margin-top: 4px;

        .value-container {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-bottom: -2px;
            margin-right: 4px;
        }

        .amount-fees {
            display: flex;
            align-items: center;
            margin-top: 2px;
            flex-wrap: wrap;

            * {
                margin: 2px 0;
            }
        }
    }
}


@media only screen and (max-width: 720px) {
    .eibc-client-statistics .property-value.orders-property-value .amount-fees {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
}
