.sponsorship-list-header {
    margin: 8px 0 12px;
    line-height: 36px;
    align-items: center;
    display: flex;

    .sponsorships-controls {
        margin-left: 16px;
        display: flex;

        .sponsorships-filters {
            width: 320px;

            .sponsorship-type-select {
                flex: initial;
                white-space: nowrap;
            }
        }
    }

    &.simple {
        margin-top: 0;
    }
}

.sponsorship-list-container {
    overflow: auto;

    .sponsorship-list {
        width: 100%;

        .sponsorship-row {
            height: 56px;

            .name-column {
                width: 30%;
            }

            .type-column {
                width: 20%;
            }

            .sponsored-column {
                line-height: 18px;
                width: 20%;

                .weight {
                    white-space: nowrap;
                    margin-left: 4px;
                }
            }

            .streamed-column {
                width: 20%;
                line-height: 18px;

                .column-content {
                    flex-direction: column;
                    align-items: start;
                }
            }

            .actions-column {
                width: 10%;

                .revoke-action {
                    --background-color-inverse: var(--red-dark);
                    --button-text-color: var(--cream);
                    --button-color-hover: rgba(var(--red-dark-rgb), 0.7);
                    --button-color-active: rgba(var(--red-dark-rgb), 0.5);

                    margin-right: 8px;
                }
            }

            .column-badge {
                white-space: nowrap;
            }

            .voting-power-input {
                width: 100px;
            }
        }
    }

    .no-data {
        display: flex;
        height: 56px;
        align-items: center;
        justify-content: center;
        color: var(--text-color-secondary);
    }

    &.simple {
        font-size: 13px;
        border: 1px solid var(--border-color);
        border-radius: 6px 6px 0 0;
        max-height: min(296px, 60vh);

        .sponsorship-list .sponsorship-row {
            .sponsorship-item-logo {
                width: 24px;
                height: 24px;
            }

            .sponsorship-item-title .item-name {
                white-space: initial;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .sponsorship-list-header .sponsorships-controls {
        margin-left: 8px;
    }
}

@media only screen and (max-width: 720px) {
    .sponsorship-list-header {
        flex-direction: column;
        align-items: start;

        .sponsorships-controls {
            width: 100%;
            margin-left: 0;

            .sponsorships-filters {
                flex: 1;
            }
        }
    }

    .sponsorship-list-container {
        .sponsorship-list {
            font-size: 12px;

            .sponsorship-row {
                .sponsored-column {
                    flex-direction: column;
                    align-items: start;

                    .weight {
                        margin-left: 0;
                    }
                }

                .column-badge {
                    white-space: initial;
                }
            }
        }

        &.simple {
            max-height: min(296px, 30vh);
        }
    }
}
