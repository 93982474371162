.options-modal.form-control {
    --visible-options-count: 8; //todo: handle

    height: initial;
    background-color: var(--control-color);
    border: 1px solid var(--border-color);
    padding: 0;
    margin: 4px 0;

    .menu-action-list {
        padding: 0;

        .link, .actions-group-label {
            padding: var(--control-padding);
        }

        .actions-group-label {
            padding-bottom: 4px;
        }
    }

    .option {
        --link-color-hover: var(--text-color);

        .multiselect-checkbox {
            margin-right: 12px;
        }

        &:not(.disabled):hover, &.active {
            background-color: rgba(var(--cream-very-dark-rgb), 0.04);
        }
    }
}
