.edit-app-dialog.edit-app-dialog {
    width: 640px;

    .dialog-header {
        margin-bottom: 16px;
    }

    .app-logo-container {
        flex: 0;
        margin-right: 24px;

        .logo-uploader {
            width: 216px;
            height: 216px;
            justify-content: center;
            text-align: center;
        }
    }

    .save-button {
        width: 100%;
    }

    .dialog-footer {
        margin-top: 24px;
    }
}

@media only screen and (max-width: 720px) {
    .edit-app-dialog.edit-app-dialog .app-logo-container {
        margin-right: 0;

        .logo-uploader {
            width: max(100%, 216px);
        }
    }
}

