.rollapp-liquidity-dialog.rollapp-liquidity-dialog {
    width: 640px;

    .unsupported-wallet-message {
        margin-bottom: 16px;
    }

    .liquidity-dialog-content {
        margin: 16px 0 24px;
    }

    .liquidity-control-container {
        margin-top: 24px;
        flex: 1;

        &:not(:first-of-type) {
            margin-left: 12px;
        }
    }

    .liquidity-control-label {
        font-size: 14px;
        display: flex;
        margin: 24px 0 8px;
        align-items: center;
        justify-content: space-between;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .operator-groups-selector {
        width: 100%;
    }

    .tokens-section {
        padding: 16px 12px;
        max-height: 346px;
        overflow: auto;

        .add-token-button {
            width: 100%;
        }
    }

    .save-button {
        flex: 1;
    }

    .dialog-footer {
        margin-top: 24px;
    }
}

.rollapp-liquidity-token-container {
    display: flex;
    justify-content: center;

    .amount-tx-container {
        flex: 1;
    }

    .selected-currency-balance {
        margin: 4px 0 -12px;
        font-size: 12px;
    }
}

@media only screen and (max-width: 720px) {
    .rollapp-liquidity-dialog.rollapp-liquidity-dialog {
        .liquidity-control-label {
            margin: 16px 0 8px;
        }
    }
}
