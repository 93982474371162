$container-padding: 16px;

.snackbar-message {
    --type-icon-color: var(--text-color);
    
    min-width: 100px;
    position: relative;
    padding: 0 16px;
    display: flex;
    align-items: center;
    min-height: 40px;
    margin-bottom: var(--messages-space);
    pointer-events: auto;
    font-size: 14px;
    font-weight: normal;
    border-radius: 4px;
    transition: transform 150ms ease-in-out, background-color 200ms ease-in-out;
    background-color: var(--background-color-secondary);
    color: var(--text-color);
    border: 1px solid var(--border-color-dark);
    cursor: default;
    box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.25);
    
    .message-type-icon.message-type-icon {
        margin-right: 16px;
        min-width: 24px;
        width: 24px;
        
        svg path {
            fill: var(--type-icon-color);
        }
    }
    
    .message-content {
        padding: 12px 0;
        line-height: 24px;
        display: flex;
        flex-direction: column;
        
        &.have-prefix {
            padding-left: 16px;
        }
        
        &.have-close-button {
            padding-right: 16px;
        }
    }
    
    .message-action {
        font-weight: bold;
        
        &:first-of-type {
            margin-left: 32px;
        }
    }
    
    .close-action {
        position: absolute;
        top: -8px;
        right: -8px;
        padding: 0;
        width: 32px;
        border-radius: 16px;
        border: 1px solid var(--border-color);
    }
    
    &:hover {
        background-color: #2c2826;
    }
    
    &.success {
        --type-icon-color: var(--green);
        
    }
    
    &.warning {
        --type-icon-color: var(--orange);
    }
    
    &.error {
        --type-icon-color: var(--red);
    }
    
    &.message-enter {
        transform: translateX(calc(100% + #{$container-padding}));
    }
    
    &.message-enter-active {
        transition-timing-function: ease-in;
        transform: translateX(-$container-padding * 1.5);
    }
    
    &.message-enter-done {
        transition-timing-function: ease-out;
        transform: translateX(0);
    }
    
    &.message-exit {
        transform: translateX(0);
    }
    
    &.message-exit-active {
        transition-timing-function: ease-in;
        transform: translateX(calc(100% + #{$container-padding}));
    }
}

@media only screen and (max-width: 720px) {
    .snackbar-message {
        padding: 0 8px;
    
        .message-content {
            padding: 8px 0;
        }
    
        .message-action:first-of-type {
            margin-left: 0;
        }
    }
}
