@font-face {
    font-family: 'Inter';
    src: local('Inter'), url('../assets/fonts/Inter/Inter-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 100;
    src: local('Inter'), url('../assets/fonts/Inter/Inter-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 200;
    src: local('Inter'), url('../assets/fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 300;
    src: local('Inter'), url('../assets/fonts/Inter/Inter-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 400;
    src: local('Inter'), url('../assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 500;
    src: local('Inter'), url('../assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 600;
    src: local('Inter'), url('../assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 700;
    src: local('Inter'), url('../assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
}


@font-face {
    font-family: 'Inter';
    font-weight: 800;
    src: local('Inter'), url('../assets/fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
}


@font-face {
    font-family: 'DS-DIGI';
    font-weight: 400;
    src: local('ds-digi'), url('../assets/fonts/ds-digital/DS-DIGI.ttf') format('truetype');
}

@font-face {
    font-family: 'DS-DIGI';
    font-weight: 800;
    src: local('ds-digi'), url('../assets/fonts/ds-digital/DS-DIGIB.ttf') format('truetype');
}

@font-face {
    font-family: "TWKEverettMedium";
    src: url('../assets/fonts/TWKEverett/TWKEverett-Medium.woff2') format('woff2'),
    url('../assets/fonts/TWKEverett/TWKEverett-Medium.woff') format('woff'),
    url('../assets/fonts/TWKEverett/TWKEverett-Medium.ttf') format("truetype");
    font-weight: 500;
}

