.account-balances {
    list-style: none;
    margin: 0;
    overflow: auto;
    padding: 8px 0;

    .no-balances {
        text-align: center;
        display: block;
        padding: 8px 0;
        color: var(--text-color-secondary);
    }

    .balances-loader {
        position: absolute;
        left: calc(50% - 12px);
        z-index: 1;
    }

    .balance-row {
        display: flex;
        height: 48px;
        align-items: center;
        padding: 0 12px;

        .currency-logo {
            width: 24px;
            height: 24px;
            max-width: 24px;
            max-height: 24px;
            border-radius: 12px;
            margin-right: 8px;
            object-fit: cover;
        }

        .currency-name-container {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            font-weight: 600;
            max-width: 180px;
        }

        .currency-option-balance {
            display: block;
            flex-direction: column;
            flex: 1;
            text-align: right;
            font-weight: 600;
            margin-left: 8px;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .currency-network, .balance-value, .transfer-time {
            font-size: 12px;
            margin-top: 2px;
            color: var(--text-color-secondary);
            display: block;
            line-height: 12px;
        }

        .currency-network {
            font-weight: 400;
        }

        .token-suffix {
            font-size: 12px;
            margin-left: 4px;
            color: var(--text-color-secondary);
            display: block;
            line-height: 12px;
        }

        .finalized-badge {
            margin-left: 8px;
        }

        &.canClick {
            cursor: pointer;

            &:hover {
                background-color: rgba(var(--cream-rgb), 0.05);
            }
        }
    }
}
