.buy-sell-section.section {
    display: flex;
    width: 100%;
    flex-direction: column;

    .buy-sell-tab-bar {
        margin: -12px;
        width: calc(100% + 24px);
        margin-bottom: 12px;
    }

    .transaction-bottom-space {
        display: none;
    }

    .inverse-asset-section {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 16px;
        min-height: 24px;
        margin: 4px 0 12px;

        .inverse-asset-amount {
            text-align: right;
            color: var(--text-color-secondary);
            margin-left: 8px;
            flex: 1;
        }

        .switch-token {
            padding: 0 2px;
        }
    }

    .amount-shortcuts {
        display: flex;
        margin: -2px 0 16px;

        .amount-badge {
            --padding: 2px;

            flex: 1;
            justify-content: center;
            font-weight: bold;
            text-align: center;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }

            &:not(:last-of-type) {
                margin-right: 8px;
            }
        }
    }

    .trade-button {
        --background-color-inverse: rgba(var(--light-green-rgb), 0.7);
        --button-color-hover: var(--light-green);
        --button-color-active: var(--dark-green);
        --button-color-disable: rgba(var(--light-green-rgb), 0.1);
        --text-color-inverse: var(--cream);

        margin-bottom: 12px;

        &.sell {
            --background-color-inverse: rgba(var(--red-rgb), 0.7);
            --button-color-hover: var(--red);
            --button-color-active: var(--red-dark);
            --button-color-disable: rgba(var(--red-rgb), 0.1);
        }
    }

    .slippage-alert {
        margin-bottom: 12px;
    }

    .trade-details-section {
        padding: 0 12px;

        .token-price {
            height: 40px;
        }

        .fees-container {
            padding: 4px 0;

            .fee-property {
                height: 32px;

                &.border {
                    margin-top: 4px;
                    padding-top: 4px;
                }
            }
        }

    }
}

@media only screen and (max-width: 1024px) {
    .buy-sell-section.section {

        .buy-sell-tab-bar {
            margin-bottom: 16px;
        }

        .inverse-asset-section {
            margin: 8px 0 20px;
            font-size: 14px;
        }

        .trade-button {
            margin-bottom: 16px;
        }
    }
}

@media only screen and (max-width: 720px) {
    .buy-sell-section.section {
        .inverse-asset-section {
            margin: 6px 0 14px;
            font-size: 12px;
        }

        .trade-button {
            margin-bottom: 12px;
        }
    }
}

