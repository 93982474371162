.iro-plan-section {
    .disabled-switch .space {
        display: none;
    }

    .target-raise {
        input.input {
            width: 64px;
            flex: 1;
        }

        .target-raise-suffix {
            font-size: 12px;
            margin-left: 8px;
            font-weight: 600;

            &.above {
                position: absolute;
                top: -20px;
                right: 0;
            }
        }
    }

    .target-valuation {
        margin-top: 4px;
        color: var(--text-color-secondary);
    }
}
