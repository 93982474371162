.terms-agreement-dialog.terms-agreement-dialog {
    width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .terms-icon {
        --color: var(--light-green);

        margin: 40px 0 24px;
        width: 128px;
        height: 128px;
    }

    .agreements {
        font-size: 14px;
        margin: 32px 0;

        .link {
            color: var(--light-green);
        }

        .section {
            background-color: var(--black-light);
            color: var(--cream-dark);

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }

    .agree-checkbox-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        color: var(--cream-dark);
        font-size: 14px;

        .agree-checkbox {
            margin-right: 8px;
        }
    }

    .dialog-actions {
        width: 100%;

        .continue-button {
            --background-color-inverse: var(--light-green);
            --button-color-hover: rgba(var(--light-green-rgb), 0.7);
            --button-color-active: rgba(var(--light-green-rgb), 0.5);

            width: 100%;
        }
    }
}

.terms-pdf-dialog.terms-pdf-dialog {
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
    position: relative;
    overflow: hidden;
    background-color: transparent;

    .pdf-iframe {
        border: none;
        background-color: transparent;
    }

    .dialog-header {
        background-color: transparent;
    }
}

@media only screen and (max-width: 720px) {
    .terms-agreement-dialog.terms-agreement-dialog {
        .terms-icon {
            margin: 16px 0;
            width: 72px;
            height: 72px;
        }

        .agreements {
            margin: 24px 0;
        }
    }
}
