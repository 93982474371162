.pool-list-header {
    margin: 24px 0 8px;
    line-height: 36px;
    align-items: center;
    display: flex;
}

.pool-list-container {
    overflow: auto;

    .asset-logo {
        object-fit: cover;
        width: 28px;
        height: 28px;
        border-radius: 14px;
        z-index: 1;

        &:last-of-type {
            z-index: 0;
            margin-left: -9px;
        }
    }

    .pool-assets-names {
        margin-left: 8px;
        white-space: nowrap;
        display: flex;
        align-items: center;
    }

    .pool-list {
        --index-column-width: 60px;

        width: 100%;

        .pool-row {
            height: 64px;
        }
    }

    .no-data {
        display: flex;
        height: 64px;
        align-items: center;
        justify-content: center;
        color: var(--text-color-secondary);
    }

    .actions-column {
        width: 180px;
    }

    .bonded-percentage {
        color: var(--cream-very-dark);
        margin-left: 4px;
        font-size: 12px;
    }
}


@media only screen and (max-width: 1024px) {
    .pool-list-container .actions-column {
        width: initial;
    }
}


@media only screen and (max-width: 720px) {
    .pool-list-header {
        flex-direction: column;
        align-items: start;
    }

    .pool-list-container {
        .pool-list {
            --index-column-width: 50px;

            font-size: 12px;
        }

        .pool-assets-names {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .incentives-badge {
                margin-top: 4px;
                margin-left: 0;
            }
        }

        .asset-logo {
            width: 24px;
            height: 24px;
            border-radius: 12px;

            &:last-of-type {
                margin-left: -8px;
            }
        }
    }
}


