.manage-rollapps {
    .rollapps-table-actionbar {
        margin: 24px 0 12px;
        line-height: 36px;
        align-items: center;
        display: flex;

        .table-header {
            flex: 1;
        }

        .manage-rollapp-button {
            margin-left: 16px;
        }
    }

    .managed-rollapps-table-container {
        overflow: auto;

        .table {
            --row-height: 72px;

            .alias-column .alias-link {
                font-weight: bold;
                font-style: italic;
            }

            .actions-column .manage-rollapp-action {
                margin-right: 8px;
            }

            .creation-step-done {
                margin-left: 8px;
            }

            .vm-logo {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }

        .no-data {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px 0;
            color: var(--text-color-secondary);
        }
    }
}

@media only screen and (max-width: 720px) {
    .managed-rollapps-table-container .table {
        font-size: 12px;
    }
}

