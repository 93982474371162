.tokenomics-chart-container {
    width: 100%;

    .customized-label {
        fill: var(--cream);
    }

    .pie-chart-container {
        svg {
            overflow: visible;
        }

        .legend-item {
            margin: 8px 0;
            display: flex;
            align-items: center;

            .bullet {
                font-size: 10px;
                margin-right: 8px;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .tokenomics-chart-container {
        .customized-label {
            font-size: 12px;
        }

        .pie-chart-container .recharts-legend-wrapper {
            width: initial !important;
        }

        .legend-item {
            font-size: 12px;
        }
    }
}
