.proposal-type {
    .proposal-select-control {
        width: 100%;
    }

    .proposal-change-label {
        margin: 8px 0 4px;
        display: block;
    }

    .proposal-change-item {
        position: relative;
        padding: 12px 16px 16px;
        background-color: var(--black-light);
        margin-bottom: var(--page-small-padding);
        border-radius: 8px;
    }

    .proposal-add-item-button {
        width: 100%;
    }

    .proposal-remove-item-button {
        position: absolute;
        top: 6px;
        right: 8px;
    }

    .proposal-content-section {
        &:not(:first-of-type) {
            padding-top: var(--page-padding);
            margin-top: var(--page-padding);
            border-top: 1px solid var(--border-color-dark);
        }
    }

    .sponsorship-switch {
        display: block;
    }
}
