.link {
    --link-color-normal: var(--cream-dark);
    --link-color-disable: var(--text-color-disabled);
    --link-color-hover: var(--text-color);
    --link-color-active: var(--text-color);
    --link-color: var(--link-color-normal);

    color: var(--link-color);
    position: relative;
    display: flex;
    padding: 8px 10px;
    line-height: 24px;
    align-items: center;

    &:not(.disabled):hover {
        --link-color: var(--link-color-hover);

        &.external {
            text-decoration: underline;
        }
    }

    &:not(:first-of-type) {
        margin-left: 8px;
    }

    .link-loader {
        position: absolute;
    }

    &.active {
        --link-color: var(--link-color-active);
    }

    &.disabled {
        --link-color: var(--link-color-disable);

        cursor: default;

        img, &.icon-color-original svg {
            opacity: 0.4;
        }
    }

    &.inline {
        display: inline-flex;
        width: fit-content;
        padding: 0;
        margin: 0;
        font-weight: bold;
    }

    &.loading {
        display: flex;
        justify-content: center;
    }

    .arrow-down {
        width: 13px;
        height: 7.5px;
        margin-left: 10.5px;

        path, g {
            fill: var(--link-color)
        }
    }

    svg, img {
        max-width: 20px;
        max-height: 20px;
        width: 20px;
        height: 20px;
        display: block;
    }

    &.icon-color-fill > svg {
        path, g {
            fill: var(--link-color);
        }
    }

    &.icon-color-stroke > svg {
        path, g {
            stroke: var(--link-color);
        }
    }

    &.small {
        padding: 4px 6px;
        line-height: 20px;
        font-size: 13px;

        svg, img {
            max-width: 18px;
            max-height: 18px;
            width: 18px;
            height: 18px;
        }

        &.inline {
            padding: 0;
        }
    }
}
