.transaction-fee {
    color: var(--text-color-secondary);
    font-size: 14px;
    display: flex;
    align-items: center;
    line-height: 18px;
    justify-content: space-between;

    .transaction-fee-value {
        font-weight: 600;
    }

    &:not(:first-of-type) {
        margin-top: 12px;
    }
}
