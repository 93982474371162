.stickable-network-comments {
    .post-comment-section {
        width: 100%;
        border: 1px solid var(--border-color-dark);
        margin-bottom: 16px;
    }

    &.sticky {
        height: calc(100vh - var(--top-bar-height) - var(--test-env-bar-height));
        margin-bottom: calc(var(--footer-height) * -1 - var(--page-large-padding));
        position: sticky;
        display: flex;
        flex-direction: column;
        top: calc(var(--top-bar-height) + var(--test-env-bar-height));
        z-index: 20;

        .comments.section {
            flex: 1;
            height: 0;
            padding-bottom: 74px;
        }

        .post-comment-section {
            box-shadow: 0 -2px 3px 2px rgba(0, 0, 0, 0.25);
            border: 1px solid var(--border-color-dark);
            position: sticky;
            margin-top: -74px;
            margin-bottom: 0;
            bottom: 0;
            z-index: 2;
        }
    }
}


