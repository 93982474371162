.bonding-curve-chart-container {
    --color: var(--green);

    position: relative;

    .property-value {
        display: none;
    }

    .chart-elements {
        width: calc(100% + 12px);
    }

    .chart-grid {
        stroke: rgba(var(--cream-rgb), 0.1);
    }

    .area-background {
        stop-color: var(--color);
        transition: stop-color 150ms ease-in-out;
        z-index: 0;
    }

    .area-chart .recharts-area-curve {
        stroke: var(--color);
        z-index: 0;
        stroke-width: 2;
        transition: stroke 150ms ease-in-out;
    }

    .curve-chart-header {
        margin-bottom: 8px;
    }

    &:not(.disabled) {
        .recharts-wrapper {
            cursor: pointer !important;
        }

        &:hover {
            border-color: rgba(var(--cream-rgb), 0.1)
        }
    }
}
