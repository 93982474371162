.remove-rollapp-liquidity-dialog.remove-rollapp-liquidity-dialog {
    width: 480px;


    .liquidity-dialog-content {
        margin: 16px 0 24px;
    }

    .remove-button {
        --background-color-inverse: var(--red-dark);
        --button-text-color: var(--cream);
        --button-color-hover: rgba(var(--red-dark-rgb), 0.7);
        --button-color-active: rgba(var(--red-dark-rgb), 0.5);
        --button-color-disable: rgba(var(--red-rgb), 0.1);

        flex: 1;
        margin-bottom: 24px;
    }
}

