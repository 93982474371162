.iro-time-badge {
    font-weight: 600;
    position: relative;

    .badge-label {
        z-index: 1;
        line-height: 15px;

        &.clock {
            letter-spacing: 1px;
            font-size: 14px;
            font-weight: 800;
            font-family: DS-DIGI, monospace;
        }
    }

    &:before {
        content: '';
        background-color: rgba(var(--black-rgb), 0.5);
        position: absolute;
        border-radius: var(--rounder-border-radius);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
