.apps-section {
    .apps-table-container {
        border: 1px solid var(--border-color);
        border-radius: 6px 6px 0 0;
        overflow: auto;

        .table {
            --row-height: 48px;

            .app-logo {
                width: 32px;
                height: 32px;
                border-radius: 4px;
                margin-right: 16px;
                object-fit: cover;
            }

            .app-url {
                padding: 0;
            }

            .name-column {
                min-width: 256px;
            }

            .description-column {
                max-width: 40vw;
                display: block;
            }
        }

        .bottom-bar-container {
            border-bottom: none;
        }

        .no-data {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px 0;
            color: var(--text-color-secondary);
        }
    }
}

@media only screen and (min-width: 1680px) {
    .apps-section .apps-table-container .table .description-column {
        max-width: 35vw;
    }
}

@media only screen and (max-width: 1280px) {
    .apps-section .apps-table-container .table .description-column {
        max-width: 25vw;
    }
}

@media only screen and (max-width: 1024px) {
    .apps-section .apps-table-container .table .name-column {
        min-width: initial;
    }
}
