.metadata-section {
    .tags {
        .tags-selector {
            padding-top: 0;
            padding-bottom: 0;
            align-items: center;
        }

        .rollapp-tag-badge {
            margin-right: 6px;
        }

        .more-label {
            font-weight: 600;
            color: var(--text-color-secondary);
            white-space: nowrap;
        }
    }
}
