.rollapp-liquidity-list-header {
    margin: 24px 0 12px;
    line-height: 36px;
    align-items: center;
    display: flex;

    .rollapp-liquidity-list-controls {
        display: flex;
        align-items: center;

        .rollapp-liquidity-search {
            margin-right: 16px;
        }
    }
}

.rollapp-liquidity-list-container {
    overflow: auto;

    .rollapp-liquidity-list {
        width: 100%;

        .rollapp-liquidity-row {
            height: 56px;

            .rollapp-name-container {
                min-width: 128px;
            }

            .tokens-column {
                .token-badge {
                    margin-right: 4px;
                    cursor: pointer;

                    &:not(:hover) {
                        background-color: transparent;
                    }
                }

                .token-logo {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                    border-radius: 8px;
                    object-fit: cover;
                }
            }

            .group-address-column .address-container {
                margin-left: 8px;
                color: var(--text-color-secondary);

                &:before {
                    content: '(';
                    margin-left: -4px;
                }

                &:after {
                    content: ')';
                    margin-right: -4px;
                }
            }

            .actions-column .remove-action {
                --background-color-inverse: var(--red-dark);
                --button-text-color: var(--cream);
                --button-color-hover: rgba(var(--red-dark-rgb), 0.7);
                --button-color-active: rgba(var(--red-dark-rgb), 0.5);

                margin-right: 8px;
            }
        }
    }

    .no-data {
        display: flex;
        height: 56px;
        align-items: center;
        justify-content: center;
        color: var(--text-color-secondary);
    }
}

@media only screen and (max-width: 1280px) {
    .rollapp-liquidity-list-container .rollapp-liquidity-list .rollapp-liquidity-row .group-address-column {
        flex-direction: column;
        align-items: start;

        .address-container {
            margin-left: 0;

            &:before, &:after {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .rollapp-liquidity-list-header .rollapp-liquidity-list-controls {

        .rollapp-liquidity-search {
            margin-right: 8px;
        }

        .add-rollapp-liquidity-button-text {
            display: none;
        }
    }
}

@media only screen and (max-width: 720px) {
    .rollapp-liquidity-list-header {
        flex-direction: column;
        align-items: start;

        .rollapp-liquidity-list-controls {
            width: 100%;

            .rollapp-liquidity-search {
                flex: 1;
            }
        }
    }

    .rollapp-liquidity-list-container .rollapp-liquidity-list .rollapp-liquidity-row .tokens-column .more-tokens-label {
        font-size: 12px;
    }
}
