:root {
    --top-bar-height: 64px;
    --footer-height: 48px;
    --page-padding: 24px;
    --page-small-padding: 16px;
    --page-large-padding: 44px;

    --cream-rgb: 241, 225, 212;
    --cream-dark-rgb: 192, 180, 168;
    --cream-very-dark-rgb: 140, 129, 121;
    --black-rgb: 37, 33, 32;
    --black-dark-rgb: 26, 23, 23;
    --black-light-rgb: 49, 44, 44;
    --red-rgb: 240, 89, 89;
    --light-red-rgb: 238, 110, 110;
    --red-dark-rgb: 201, 51, 51;
    --blue-rgb: 47, 103, 195;
    --light-blue-rgb: 85, 136, 221;
    --dark-blue-rgb: 28, 69, 138;
    --green-rgb: 89, 240, 190;
    --dark-green-rgb: 37, 164, 24;
    --orange-rgb: 227, 161, 49;
    --dark-orange-rgb: 218, 135, 84;
    --light-green-rgb: 120, 194, 109;
    --beige-rgb: 174, 178, 79;
    --gold-rgb: 246, 200, 62;

    --cream: rgb(var(--cream-rgb));
    --cream-dark: rgb(var(--cream-dark-rgb));
    --cream-very-dark: rgb(var(--cream-very-dark-rgb));
    --black: rgb(var(--black-rgb));
    --black-dark: rgb(var(--black-dark-rgb));
    --black-light: rgb(var(--black-light-rgb));
    --blue: rgb(var(--blue-rgb));
    --dark-blue: rgb(var(--dark-blue-rgb));
    --light-blue: rgb(var(--light-blue-rgb));
    --green: rgb(var(--green-rgb));
    --light-green: rgb(var(--light-green-rgb));
    --dark-green: rgb(var(--dark-green-rgb));
    --orange: rgb(var(--orange-rgb));
    --dark-orange: rgb(var(--dark-orange-rgb));
    --red: rgb(var(--red-rgb));
    --red-dark: rgb(var(--red-dark-rgb));
    --beige: rgb(var(--beige-rgb));
    --gold: rgb(var(--gold-rgb));

    --text-color: var(--cream);
    --text-color-secondary: var(--cream-very-dark);
    --text-color-disabled: rgba(var(--cream-very-dark-rgb), 0.4);
    --background-color: var(--black);
    --background-color-secondary: var(--black-light);
    --border-color: rgba(var(--cream-very-dark-rgb), 0.22);
    --border-color-light: rgba(var(--cream-very-dark-rgb), 0.32);
    --border-color-dark: rgba(var(--cream-very-dark-rgb), 0.12);

    --text-color-inverse: var(--black);
    --background-color-inverse: var(--cream);
}

@media only screen and (max-width: 1024px) {
    :root {
        --page-small-padding: 12px;
        --page-large-padding: 32px;
        --page-padding: 16px;
        --top-bar-height: 56px;
    }
}

@media only screen and (max-width: 720px) {
    :root {
        --page-large-padding: 24px;
        --page-small-padding: 8px;
        --page-padding: 12px;
    }
}

@media only screen and (max-width: 480px) {
    :root {
        --top-bar-height: 100px;
    }
}
