.chart-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .chart-container-header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 24px;

        .selectors-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            align-self: flex-start;
            margin-top: 8px;
            flex: 1;

            .period-selector-option {
                --button-color-default: transparent;

                padding: 6px 12px;
                border-radius: 16px;
            }

            .chart-selector {
                margin-left: 8px;
                white-space: nowrap;
                background-color: var(--black-light);
            }
        }
    }

    .chart-property {
        margin: 12px 0 0 4px;

        .property-label {
            font-size: 16px;
            margin-bottom: 4px;
            height: 20px;
        }

        .property-value {
            font-size: 20px;
            min-height: 24px;
            margin-bottom: initial;
            padding-bottom: initial;

            .statistics-change-container {
                min-height: 24px;
                line-height: 24px;
            }
        }
    }

    .chart-elements {
        display: flex;
        width: 100%;
        margin-left: -12px;

        .chart-spinner-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .chart-container .chart-property {
        margin: 0;
    }
}

@media only screen and (max-width: 850px) {
    .chart-container {
        .chart-container-header .selectors-container {
            .period-selector-option {
                padding: 6px 10px;
            }

            .chart-selector {
                font-size: 12px;
            }
        }

        .chart-elements {
            flex-direction: column;

            & > *:not(:first-of-type) {
                margin-top: 24px;
            }
        }
    }
}

