.button {
    --button-height: 40px;
    --button-font-size: 14px;
    --button-padding: 4px 22px;
    --button-text-color: var(--text-color);
    --button-color-default: var(--background-color-secondary);
    --button-color: var(--button-color-default);
    --button-color-active: rgba(var(--cream-very-dark-rgb), 0.5);
    --button-color-hover: rgba(var(--cream-very-dark-rgb), 0.25);
    --button-text-color-hover: var(--text-color);
    --button-text-color-disable: var(--text-color-disabled);
    --button-color-disable: rgba(var(--cream-very-dark-rgb), 0.1);
    --button-border-radius: 8px;
    --button-icon-size: 20px;
    --icon-button-size: 32px;
    --icon-button-icon-size: 20px;

    outline: none;
    border: none;
    border-radius: var(--button-border-radius);
    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition-property: background-color, color;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
    font-weight: 600;
    height: var(--button-height);
    font-size: var(--button-font-size);
    padding: var(--button-padding);
    color: var(--button-text-color);
    background-color: var(--button-color);

    &:not(.disabled) {
        &:hover, &.hover {
            --button-text-color: var(--button-text-color-hover);
            --button-color: var(--button-color-hover);
        }

        &:focus-visible, &.focus {
            box-shadow: 0 0 0 3px var(--button-text-color-disable);
            z-index: 1;
        }

        &:active, &.active {
            --button-color: var(--button-color-active);
        }
    }

    svg, img {
        min-width: var(--button-icon-size);
        min-height: var(--button-icon-size);
        max-width: var(--button-icon-size);
        max-height: var(--button-icon-size);
        display: block;
    }

    .button-loader {
        position: absolute;
    }

    &.icon-color-fill > svg {
        path, g {
            fill: var(--button-text-color);
        }
    }

    &.icon-color-stroke > svg {
        path, g {
            stroke: var(--button-text-color);
        }
    }

    &.xs {
        --button-height: 22px;
        --button-font-size: 10px;
        --button-padding: 4px 6px;
        --button-icon-size: 16px;
        --icon-button-size: 24px;
        --icon-button-icon-size: 16px;
        --button-border-radius: 3px;
    }

    &.small {
        --button-icon-size: 18px;
        --button-height: 32px;
        --button-font-size: 12px;
        --button-padding: 4px 16px;
        --icon-button-size: 28px;
        --icon-button-icon-size: 18px;
        --button-border-radius: 6px;
    }

    &.large {
        --button-height: 44px;
        --button-font-size: 15px;
        --button-padding: 4px 22px;
        --button-icon-size: 22px;
        --icon-button-size: 36px;
        --icon-button-icon-size: 22px;
    }

    &.x-large {
        --button-height: 52px;
        --button-font-size: 16px;
        --button-padding: 4px 22px;
        --icon-button-size: 44px;
        --icon-button-icon-size: 28px;
    }

    &.primary {
        --button-color: var(--background-color-inverse);
        --button-color-hover: var(--cream-dark);
        --button-color-active: var(--cream-very-dark);
        --button-text-color: var(--text-color-inverse);
        --button-text-color-hover: var(--text-color-inverse);
    }

    &.icon {
        --button-color: transparent;
        --button-text-color: var(--text-color-secondary);
        --button-color-hover: transparent;
        --button-text-color-hover: var(--text-color);
        --button-height: var(--icon-button-size);

        border-radius: var(--icon-button-size);
        padding: 0;
        min-width: var(--icon-button-size);
        justify-content: center;

        svg, img {
            min-width: var(--icon-button-icon-size);
            min-height: var(--icon-button-icon-size);
            max-width: var(--icon-button-icon-size);
            max-height: var(--icon-button-icon-size);
            margin-left: initial;
        }

        &:not(.disabled) {
            &:active, &.active {
                --button-text-color: var(--text-color);
                --button-color: transparent;
            }
        }
    }

    &.disabled {
        --button-text-color: var(--button-text-color-disable);

        &:not(.icon) {
            --button-color: var(--button-color-disable);
        }

        cursor: default;

        .secondary-text {
            color: var(--button-text-color);
        }

        img, &.icon-color-original svg, .icon-color-original svg, &.icon-color-fill svg, .icon-color-fill svg, &.icon-color-stroke svg, .icon-color-stroke svg {
            opacity: 0.4;
        }
    }
}

@media only screen and (max-width: 720px) {
    .button {
        --button-padding: 4px 12px;
    }
}

@media (hover: none) {
    .button:not(.disabled).icon {
        --button-text-color: var(--cream-dark);
    }
}

