.iro-allocation-info {
    margin-bottom: 24px;
    line-height: 24px;

    .update-tokenomics-button {
        --button-height: 28px;
        --button-padding: 4px 12px;
    }

    .iro-description {
        margin-top: 8px;
    }
}
