$dialog-width: 540px;

.create-pool-dialog.create-pool-dialog {
    width: $dialog-width;
    display: flex;
    flex-direction: column;

    .dialog-content {
        margin-top: 24px;
    }

    .content-loading {
        margin: 0 auto;
    }

    .submit-button {
        width: 100%;
    }

    .token-label {
        display: block;
        margin-bottom: 12px;

        &:not(:first-of-type) {
            margin-top: -32px;
        }
    }
}
