.validators-list-header {
    margin: 24px 0 12px;
    min-height: 36px;
    align-items: center;
    display: flex;
}

.validator-list-container {
    overflow: auto;
}

.validator-list {
    width: 100%;

    .validator-row, .validator-row-header{
        height: 48px;

        .menu-column {
            width: 168px;

            .stake-action {
                margin-right: 8px;
            }
        }

        .validator-logo {
            width: 28px;
            height: 28px;
            min-width: 28px;
            object-fit: cover;
            border-radius: 14px;
            overflow: hidden;
            margin-right: 12px;

            &.fallback {
                background-color: var(--cream);
                padding: 4px;
            }
        }

        .column-currency {
            color: var(--seconday-text-color);
            font-size: 10px;
            margin-left: 4px;
            vertical-align: middle;
        }

        &.selected {
            background-color: rgba(var(--cream-rgb), 0.06);
        }

        &.selectable {
            cursor: pointer;

            &:hover {
                background-color: var(--hover-color);
            }
        }
    }

    .staked-validator-row {
        background-color: rgba(var(--blue-rgb), 0.2);
    }
}

.no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    color: var(--text-color-secondary);
}
