.file-uploader {
    cursor: pointer;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    border: 2px dashed var(--border-color);
    position: relative;
    transition: background-color 150ms ease-in-out;

    .upload-icon {
        width: 18px;
        height: 18px;

        .on-image {
            z-index: 10;
            background-color: rgba(var(--black-rgb), 0.4);
            padding: 8px;
            border-radius: 24px;
            opacity: 0;
            transition: opacity 150ms ease-in-out;
        }
    }

    .image-preview {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;

        &.dark {
            opacity: 0.2;
        }

        &.circle {
            border-radius: 50%;
            border: 1px dashed var(--border-color-light);
        }
    }

    .file-drag-label {
        margin-top: 8px;
        color: var(--text-color-secondary);
    }

    .uploaded-file-name {
        margin-top: 8px;

        &.image-file {
            position: absolute;
            bottom: 0;
            padding: 4px;
            width: 100%;
            border-radius: 0 0 8px 8px;
            background-color: rgba(var(--black-rgb), 0.4);
        }

        &.hidden {
            display: none;
        }
    }

    &:not(.disabled):hover, &:not(.disabled).dragged {
        background-color: var(--background-color-secondary);

        .upload-icon.on-image {
            opacity: 1;
        }
    }

    &.error {
        border-color: var(--red);
    }

    &.disabled {
        cursor: initial;
        background-color: rgba(var(--black-dark-rgb), 0.5);
        color: var(--text-color-disabled);

        .file-drag-label {
            color: var(--text-color-disabled);
        }

        .icon-container {
            --color: var(--text-color-disabled);
        }
    }
}
