.tab-bar {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    .tab-action-list {
        display: flex;
        position: relative;
        width: 100%;
        list-style: none;
        min-height: 40px;
        margin: 0;
        overflow: auto;
        padding: 0 0 1px;
        border-bottom: 1px solid var(--border-color);

        .tab-action {
            padding: 0 16px;
            height: 100%;
            cursor: pointer;
            background-color: transparent;
            border: none;
            color: var(--text-color-secondary);
            border-bottom: 1px solid transparent;

            &:not(:last-of-type) {
                margin-right: 24px;
            }

            &:not(.disabled) {

                &:hover {
                    color: var(--cream-dark);
                }

                &.active {
                    color: var(--text-color);
                    border-color: var(--cream);
                }
            }

            &.disabled {
                color: var(--text-color-disabled);
                cursor: default;
            }
        }
    }

    .tab.scrolled {
        overflow: auto;
        flex: 1;
    }

    &.uniform-tab-width {
        .tab-action-list .tab-container {
            flex: 1;

            .tab-action {
                width: 100%;
            }
        }
    }

    &.large .tab-action-list {
        min-height: 56px;

        .tab-action h6 {
            font-size: 16px;
        }
    }
}
