.network-entry {
    display: flex;
    align-items: center;

    .network-logo {
        width: 34px;
        height: 34px;
        border-radius: 17px;
        object-fit: cover;
        z-index: 1;
        margin: 0 7px;
    }

    .network-title {
        margin-left: 10px;
        display: flex;
        flex-direction: column;

        .network-name {
            max-width: 300px;

            &:hover {
                text-decoration: underline;
            }
        }

        .network-token {
            color: var(--text-color-secondary);
            font-size: 12px;
            margin-top: 2px;
            font-weight: 600;
        }
    }

    &.small {
        .network-logo {
            width: 30px;
            height: 30px;
            border-radius: 15px;
            margin: 0 5px;
        }

        .network-title {
            margin-left: 8px;

            .network-token {
                margin-top: 1px;
                font-size: 11px;
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .network-entry {
        .network-logo {
            width: 30px;
            height: 30px;
            border-radius: 15px;
            margin: 0 5px;
        }

        .network-title {
            margin-left: 8px;

            .network-name {
                max-width: 120px;
            }

            .network-token {
                margin-top: 1px;
                font-size: 11px;
            }
        }
    }
}
