.network-header-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 8px;

    .network-header {
        display: flex;
        align-items: center;
        width: 100%;
        flex: 10;
        padding: 8px 0;
        margin-right: 12px;

        .network-logo {
            object-fit: cover;
            margin-right: 12px;
            width: 42px;
            height: 42px;
            border-radius: 21px;
        }

        .network-details {
            display: flex;
            flex-direction: column;

            .network-name-container {
                display: flex;
                align-items: center;
                line-height: initial;

                .network-name {
                    margin-right: 6px;
                    white-space: nowrap;
                }

                .rollapp-alias {
                    font-style: italic;
                    font-weight: 600;
                    font-size: 12px;
                    margin-right: 4px;
                    color: var(--text-color-secondary);
                }

                .fair-launch-tag {
                    --color: var(--orange);

                    width: 22px;
                }
            }

            .network-short-description {
                color: var(--text-color-secondary);
                margin-top: 4px;
                font-size: 12px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .inactive-badge {
                    margin-top: 4px;
                }

                .website {
                    color: var(--text-color-secondary);
                    white-space: nowrap;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .network-actions {
        display: flex;
        flex: 1;
        align-self: flex-end;
        margin-top: 8px;

        & > .button {
            white-space: nowrap;
            flex: 1;

            &:not(:last-of-type) {
                margin-right: 12px;
            }
        }
    }
}

.network-deposit-withdraw-dialog {
    .network-selector button.form-control {
        --control-color-normal: transparent;
    }
}

@media only screen and (max-width: 1024px) {
    .network-header-container .network-header {
        .network-details .network-short-description {
            margin-top: 2px;
        }

        .network-logo {
            width: 36px;
            height: 36px;
            margin-right: 10px;
        }
    }
}

@media only screen and (max-width: 720px) {
    .network-header-container {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 12px;

        .network-header {
            margin-right: 0;

            .network-logo {
                margin-right: 8px;
            }

            .network-details .network-name-container {
                flex-wrap: wrap;

                .network-name {
                    white-space: initial;
                }
            }
        }

        .network-actions {
            width: 100%;

            .add-asset-to-wallet-action {
                display: none;
            }
        }
    }
}
