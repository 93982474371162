.sponsorship-distribution-chart {
    opacity: 0;
    transition: opacity 300ms ease-in-out;

    svg {
        overflow: visible;
    }

    .customized-label {
        fill: var(--cream);
    }

    &.visible {
        opacity: 1;
    }
}
