.total-supply-chart-container {

    .customized-label {
        fill: var(--cream);
    }

    .recharts-legend-wrapper {

        .recharts-legend-item {
            width: 140px;
            margin-bottom: 10px;
        }
    }

    .customized-legend {
        font-size: 12px;

        .value {
            color: var(--cream);

            .percentages {
                margin-left: 4px;
            }
        }
    }

    .pie-chart-container {
        opacity: 0;
        transition: opacity 300ms ease-in-out;

        svg {
            overflow: visible;
        }

        &.visible {
            opacity: 1;
        }

        &:not(.small-chart) {
            margin-top: -16px;
        }
    }

    .statistics-change {
        display: none;
    }
}

@media only screen and (max-width: 520px) {
    .total-supply-chart-container {
        .recharts-legend-wrapper {
            right: 0 !important;

            .recharts-legend-item {
                width: 100px;
                margin-right: 0 !important;
            }
        }

        .customized-legend .value .percentages {
            display: block;
            margin-left: 0;
        }
    }
}

@media only screen and (max-width: 400px) {
    .total-supply-chart-container .recharts-legend-wrapper .recharts-legend-item {
        text-align: right;
    }
}
