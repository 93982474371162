.deposit-dialog.deposit-dialog {
    width: 560px;

    .deposit-proposal-title {
        margin-top: 8px;
        margin-bottom: 24px;
        color: var(--text-color-secondary);
    }

    .deposit-dialog-confirm-button {
        margin-top: 24px;
        width: 100%;
    }
}
