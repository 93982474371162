.pool-entry {
    display: flex;
    align-items: center;

    .pool-asset-logo {
        width: 28px;
        height: 28px;
        border-radius: 14px;
        object-fit: cover;
        z-index: 1;

        &:not(:first-of-type) {
            z-index: 0;
            margin-left: -8px;
        }
    }

    .pool-title {
        margin-left: 10px;
        display: flex;
        flex-direction: column;

        .pool-assets {
            max-width: 300px;

            &:hover {
                text-decoration: underline;
            }
        }

        .pool-name {
            color: var(--text-color-secondary);
            font-size: 12px;
            margin-top: 2px;
            font-weight: 600;
        }
    }

    &.small {
        .pool-asset-logo {
            width: 24px;
            height: 24px;
            border-radius: 12px;
        }

        .pool-title {
            margin-left: 8px;

            .pool-name {
                margin-top: 1px;
                font-size: 11px;
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .pool-entry {
        .pool-asset-logo {
            width: 24px;
            height: 24px;
            border-radius: 12px;
        }

        .pool-title {
            margin-left: 8px;

            .pool-assets {
                max-width: 120px;
            }

            .pool-name {
                margin-top: 1px;
                font-size: 11px;
            }
        }
    }
}
