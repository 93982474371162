.basic-details-section {

    .actions-selector-disabled {
        pointer-events: none;
        opacity: 0.4;
    }

    .section-content {
        align-items: initial;
    }

    .image-uploader-container.control-container {
        flex: 0;
        margin-right: 24px;

        .image-uploader {
            width: 216px;
            height: 216px;
            justify-content: center;
            text-align: center;
        }
    }

    .check-icon {
        --color: var(--green);
        width: 16px;
        height: 16px;
    }

    .rollapp-id-warning-tooltip {
        margin-top: 16px;
        background-color: rgba(var(--orange-rgb), 0.8);
        font-weight: bold;
    }

    .da-control-container {

        .da-selector {
            font-weight: 500;
        }

        .da-logo {
            margin-right: 4px;
        }
    }

    .domain-control-container {

        .transaction-fee-container {
            display: flex;
            align-items: center;
            margin-bottom: -24px;
            justify-content: flex-end;

            .transaction-fee {
                font-size: 12px;

                .tooltip {
                    display: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .basic-details-section {
        .section-content {
            flex-direction: column;

            .image-uploader-container.control-container {
                margin-right: 0;

                .control-label-container {
                    width: 100%
                }

                .image-uploader {
                    width: max(100%, 256px);
                    height: 256px;

                    .image-preview {
                        width: 256px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .basic-details-section .image-uploader-container {
        align-items: center;
    }
}
