.pool-statistics {
    .pool-assets-value {
        justify-content: space-between;

        .pool-asset {
            display: flex;
            align-items: center;
            font-size: 14px;

            .currency-logo {
                object-fit: cover;
                width: 16px;
                height: 16px;
                border-radius: 8px;
                margin-right: 8px;
            }

            .asset-denom {
                margin-right: 6px;
            }
        }

        .icon-container {
            max-width: 24px;
            max-height: 24px;
            width: 24px;
            height: 24px;
        }

        .incentive-apr {
            margin-left: 8px;
            color: var(--text-color-secondary);
        }
    }

    .bonded-percentage {
        color: var(--cream-very-dark);
        margin-left: 4px;
        font-size: 14px;
    }

    .pool-statistics-cards {
        margin-bottom: 16px;
    }
}

@media only screen and (min-width: 1025px) {
    .pool-statistics {
        display: flex;

        .pool-statistics-cards {
            margin-right: 16px;
            margin-bottom: initial;
            min-width: 320px;
            width: 320px;
        }
    }
}

@media only screen and (max-width: 720px) {
    .pool-statistics {
        .pool-assets-value .pool-asset {
            font-size: 12px;

            .currency-logo {
                margin-right: 4px;
            }
        }

        .pool-chart {
            margin-top: 20px;
        }
    }
}




