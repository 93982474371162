.page {
    padding: var(--page-padding) var(--page-padding) var(--page-large-padding);
    max-width: 1680px;
    margin: 0 auto;
    width: 100%;
}

.section {
    background-color: var(--background-color);
    padding: var(--page-padding);
    border: 1px solid var(--border-color-dark);
    border-radius: 8px;

    .section-header {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
    }

    &.small {
        padding: 12px;
        border-radius: 6px;
    }
}

.space {
    flex: 1;
}

.horizontally-centered {
    display: flex;
    align-items: center;
}

.horizontally-baseline {
    display: flex;
    align-items: baseline;
}

.nowrap {
    white-space: nowrap;
}

.margin-horizontally-centered {
    margin: 0 auto;
}

.form-control {
    --control-height: 36px;
    --control-broder-radius: 4px;
    --control-padding: 8px 10px;
    --control-color-normal: var(--black-dark);
    --control-color-active: rgba(var(--black-dark-rgb), 0.7);
    --control-color-hover: rgba(var(--black-dark-rgb), 0.5);
    --control-color-disable: rgba(var(--black-dark-rgb), 0.5);
    --control-color: var(--control-color-normal);
    --control-text-color: var(--text-color-secondary);
    --control-placeholder-text-color: rgba(var(--cream-very-dark-rgb), 0.5);
    --control-text-color-disable: var(--text-color-disabled);
    --control-font-size: 14px;
    --control-text-weight: 400;

    height: var(--control-height);
    background-color: var(--control-color);
    color: var(--control-text-color);
    padding: var(--control-padding);
    font-size: var(--control-font-size);
    font-weight: var(--control-text-weight);
    border: 1px solid var(--border-color);
    border-radius: var(--control-broder-radius);
    transition: background-color 150ms ease-in-out;

    &.large {
        --control-height: 46px;
        --control-broder-radius: 8px;
        --control-padding: 12px 16px;
        --control-font-size: 16px;
        --control-text-weight: 500;
    }

    &.interactive {
        &.disabled {
            --control-text-color: var(--control-text-color-disable);
            --control-color: var(--control-color-disable);
            --control-placeholder-text-color: var(--control-text-color-disable);

            opacity: 0.7;
            cursor: default;
        }

        &:not(.disabled) {
            &:hover, &.hover {
                --control-color: var(--control-color-hover);
            }

            &:active, &.active {
                --control-color: var(--control-color-active);
            }

            &:focus-visible, &.focus {
                border-color: transparent;
            }
        }
    }
}

.control-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .control-label-container {
        display: flex;
        align-items: center;
        height: 32px;
    }
}

.controls-row {
    display: flex;

    & > * {
        flex: 1;

        &:not(:last-of-type) {
            margin-right: 8px;
        }
    }
}

.error-label {
    font-weight: 500;
    font-size: 12px;
    padding-top: 4px;
    min-height: 20px;
    line-height: 16px;
    margin-bottom: -20px;
    color: var(--red);
    display: none;

    &.visible {
        display: block;
    }
}

.required:after {
    content: '*';
    margin-left: 2px;
    color: red;
}

.separated-dot:after {
    content: '•';
    margin: 0 4px;
    color: var(--text-color-secondary);
}

@media only screen and (max-width: 720px) {
    .controls-row {
        flex-direction: column;

        & > * {
            width: 100%;

            &:not(:last-of-type) {
                margin-right: 0;
            }
        }
    }
}
