.trade-details {
    padding: 0 16px;
    flex-direction: column;

    .token-price {
        background-color: transparent;
        outline: none;
        border: none;
        width: 100%;
        display: flex;
        margin: 0;
        padding: 0;
        cursor: pointer;
        align-items: center;
        height: 48px;
        font-weight: 600;

        .arrow-down-icon {
            --color: var(--text-color-secondary);

            max-width: 16px;
            max-height: 16px;
        }
    }

    .collapsible-fees-container {
        overflow: hidden;
        transition: max-height 150ms ease-in-out;
        width: 100%;
    }

    .fees-container {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 8px 0;
        height: fit-content;
        border-top: 1px solid var(--border-color);

        .fee-property {
            color: var(--text-color-secondary);
            font-size: 14px;
            display: flex;
            height: 36px;
            align-items: center;
            justify-content: space-between;
            margin-top: 0;

            .fee-value {
                --control-height: 28px;
                --control-padding: 6px;

                font-weight: 600;
            }

            &.border {
                margin-top: 8px;
                padding-top: 8px;
                box-sizing: content-box;
                border-top: 1px solid var(--border-color)
            }
        }

        .slippage-disabled-switch {
            margin-right: 8px;
        }

        .slippage-disabled {
            opacity: 0.3;
        }
    }
}

@media only screen and (max-width: 720px) {
    .trade-details {
        width: 100%;
    }
}

