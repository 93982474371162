.pool-apr {
    white-space: nowrap;
    display: flex;
    align-items: center;

    .apr-info-icon, .thunder-icon {
        width: 14px;
        height: 14px;

        &.thunder-icon {
            --color: var(--light-green);

            margin-right: 2px;
        }

        &.apr-info-icon {
            --color: var(--cream-very-dark);

            margin-right: 4px;
        }
    }

    &.incentive {
        color: var(--light-green);
    }

    &:hover {
        text-decoration: underline;
    }
}

.apr-tooltip p {
    padding: 4px 0;
}




