.genesis-validator-modal.genesis-validator-modal {
    padding: 24px;

    .modal-content {
        color: var(--text-color-secondary);
        font-size: 14px;
        text-align: center;
        line-height: 18px;
    }

    .modal-spinner {
        --spinner-color: var(--text-color);

        margin: 0 auto 16px;
    }
}
