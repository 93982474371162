.new-delegation-dialog.new-delegation-dialog {
    width: 540px;

    .amount-input .amount-action {
        min-height: 28px;
    }

    .currency-logo {
        width: 20px;
        height: 20px;
        object-fit: cover;
        border-radius: 10px;
        margin-right: 8px;
    }

    .currency-logo, .currency-denom {
        opacity: 0.5;
    }

    .delegation-warning {
        margin-top: 16px;
    }

    .delegation-warning-loader {
        margin: 24px auto 32px;
    }

    .current-delegation {
        margin: 24px 0 12px;
    }

    .total-validators-container {
        max-height: min(296px, 60vh);
        border-radius: 6px 6px 0 0;
        border: 1px solid var(--border-color);

        .total-validators .table-row:last-of-type {
            border-bottom: none;
        }
    }

    .validator-list-header {
        margin-top: 24px;
    }
}

.delegation-confirm-button {
    width: 100%;
}

@media only screen and (max-width: 720px) {
    .new-delegation-dialog.new-delegation-dialog {
        width: 100%;

        .total-validators-container {
            max-height: min(296px, 38vh);
        }
    }
}
