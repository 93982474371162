.claimable-tokens-dialog.claimable-tokens-dialog {
    width: 480px;
    display: flex;
    flex-direction: column;

    .claimable-tokens-list {
        margin-top: 16px;
        max-height: min(320px, 70vh);
        padding: 12px 0;
    }

    .claim-action {
        width: 100%;
        margin-top: 24px;
    }

    .dialog-footer {
        margin-top: 24px;
    }
}
