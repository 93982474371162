.menu-modal.overlay-pane {
    background-color: var(--background-color-secondary);
    border-radius: 6px;
    max-height: 356px;
    overflow: auto;
    box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.25);
    border: 1px solid var(--border-color-dark);

    .menu-action-list {
        min-width: 120px;
        padding: 4px 6px;
        list-style: none;
        margin: 0;

        .menu-action-list-item:not(:last-of-type) {
            border-bottom: 1px solid var(--border-color-dark);
        }

        .actions-group-label {
            color: var(--text-color-secondary);
            font-size: 12px;
            font-weight: 600;
            padding: 4px 6px;
        }
    }
}
