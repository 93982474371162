.live-proposals-header {
    margin: 24px 0 12px;
    line-height: 36px;
    align-items: center;
    display: flex;
}

.live-proposals-container {
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .live-proposal-card {
        width: calc(50% - 8px);
        cursor: pointer;

        .proposal-card-header {
            display: flex;
            align-items: center;

            .expires-in-label {
                margin-left: 8px;

                &.alert {
                    color: var(--red);
                }
            }
        }

        .proposal-card-body {
            display: flex;
            align-items: center;
            margin-top: 16px;

            .proposal-id {
                font-weight: bold;
                text-decoration: underline;
                font-size: 15px;
            }

            .proposal-title {
                flex: 1;
                margin: 0 12px;
                overflow: hidden;
                display: -webkit-box;
                word-break: break-word;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        &:hover {
            background-color: #292524;
        }
    }

    .no-live-proposals {
        display: flex;
        height: 56px;
        width: 100%;
        align-items: center;
        justify-content: center;
        color: var(--text-color-secondary);
    }
}

@media only screen and (max-width: 720px) {
    .live-proposals-container {
        flex-direction: column;

        .live-proposal-card {
            width: 100%;
        }
    }
}

