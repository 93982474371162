.network-charts {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    .network-chart-container {
        flex: 1;
        min-width: calc(50% - 16px);

        &.full-width {
            min-width: 100%;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .network-charts {

        .network-chart-container {
            min-width: 100%;
        }
    }
}
