.init-portal-wallet-dialog.init-portal-wallet-dialog {
    width: 480px;

    .dialog-title {
        display: flex;
        align-items: center;

        .wallet-logo {
            display: block;
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }
    }

    .dialog-content {
        margin-top: 24px;
    }

    .signature-description {
        margin-bottom: 8px;
        color: var(--text-color-secondary);
        line-height: 24px;
    }

    .remember-me-section {
        margin: 20px 0 12px;
        border: 1px solid var(--border-color-dark);
        padding: 12px 16px;
        border-radius: 8px;

        .remember-me-warning {
            margin-top: 12px;
        }
    }

    .pin-code-section {
        margin: 20px 0 12px;
        border: 1px solid var(--border-color-dark);
        padding: 12px 16px;
        border-radius: 8px;

        .pin-code-characters {
            margin-top: 16px;
            display: flex;
            justify-content: center;
        }

        .pin-code-character-input {
            font-family: monospace;
            font-size: 20px;
            width: 45px;
            height: 60px;

            input.input {
                text-align: center;
            }

            &:not(:last-of-type) {
                margin-right: 8px;
            }
        }
    }

    .confirm-button {
        margin-top: 16px;
        width: 100%;
    }
}
