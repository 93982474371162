$dialog-width: 540px;

.incentives-dialog.incentives-dialog {
    width: $dialog-width;
    display: flex;
    flex-direction: column;

    .dialog-content {
        margin-top: 24px;

        .input-label {
            display: block;
            margin-bottom: 12px;
        }
    }

    .time-input {
        margin-bottom: var(--page-padding);
    }

    .error-label {
        font-weight: 500;
        font-size: 14px;
        color: var(--red);
        display: block;
        margin-top: calc(var(--page-padding) * -1 + 8px);
        margin-bottom: var(--page-padding);
    }

    .duration-slider {
        appearance: none;
        width: 100%;
        border-radius: 8px;
        height: 16px;
        position: relative;
        overflow: hidden;
        outline: none;
        margin-bottom: var(--page-padding);

        @mixin slider-thumb {
            width: 16px;
            height: 16px;
            border-radius: 12px;
            background-color: var(--cream);
            box-shadow: -$dialog-width 0 0 calc($dialog-width - 8px) var(--blue);
            cursor: pointer;
        }

        &::-webkit-slider-thumb {
            appearance: none;
            @include slider-thumb;
        }

        &::-moz-range-thumb {
            @include slider-thumb;
        }

        &::-webkit-slider-runnable-track {
            background-color: var(--background-color-secondary);
        }

        &::-moz-range-track {
            background-color: var(--background-color-secondary);
        }
    }

    .submit-button {
        margin-top: var(--page-padding);
        width: 100%;
    }
}
