.vm-property {
    .vm-logo {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }

    &.small .vm-logo {
        width: 18px;
        height: 18px;
        margin-right: 6px;
    }
}
