.network-comments.section.small {
    padding: 0;

    .comments-container {
        overflow: auto;
    }

    .comments-loader, .no-comments {
        margin: var(--page-large-padding) auto;
        display: block;
        text-align: center;
        color: var(--text-color-secondary);
    }

    .comment {
        margin: 0 12px;
        font-size: 14px;
        padding: 20px 0;

        .comment-header {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            .user-avatar, .fallback-user-avatar {
                width: 24px;
                height: 24px;
                border-radius: 12px;
                object-fit: cover;
                margin-right: 8px;
            }

            .display-name {
                font-weight: 600;
            }

            .comment-date {
                color: var(--text-color-secondary);
                font-size: 11px;
            }
        }

        .comment-body {
            font-weight: 400;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 20px;
            max-height: 120px;
            max-width: 480px;

            .asset-link, .network-link {
                font-weight: 600;
                cursor: pointer;

                &.network-link {
                    color: var(--blue);
                }

                &.asset-link {
                    color: var(--light-green);
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .comment-image {
            width: 100%;
            margin-top: 12px;
            max-width: 480px;
        }

        .comment-footer {
            margin-top: 16px;

            .reaction {
                --button-icon-size: 20px;
                --button-color-default: transparent;
                --button-color-hover: var(--background-color-secondary);

                border: 1px solid var(--border-color);
                padding: 14px 12px;
                border-radius: 16px;

                .replies-value {
                    margin-left: 6px;
                }

                .likes-value {
                    margin-left: 4px;
                }

                &.liked:not(.disabled) {
                    --button-text-color: var(--red);
                }

                &:not(.disabled) {
                    --button-text-color: var(--cream-dark);
                }

                &:not(:last-of-type) {
                    margin-right: 8px;
                }
            }
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid var(--border-color);
        }
    }
}

@media only screen and (max-width: 1024px) {
    .network-comments.section.small .comment {
        padding: 24px 0;
    }
}

@media only screen and (max-width: 720px) {
    .network-comments.section.small .comment {
        padding: 24px 0;

        .comment-body, .comment-image {
            max-width: initial;
        }
    }
}
