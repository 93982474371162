.create-rollapp-page.page {
    label {
        font-size: 14px;
    }

    .create-rollapp-stepper {
        position: sticky;
        top: calc(var(--top-bar-height) + var(--test-env-bar-height));
        z-index: 1;
        box-shadow: 0 8px 16px 0 rgba(var(--black-dark-rgb));
    }

    & > .section:not(.path-nav) {
        margin-bottom: 24px;
    }
}

.launched-iro-modal {
    .modal-title-icon {
        --color: var(--light-green);

        width: 32px;
        position: relative;
        height: 32px;
        display: block;
        margin-right: 8px;
    }

    .modal-content {
        margin: 24px 0 32px;
        color: var(--cream-dark);

        b {
            color: var(--text-color);
        }
    }

    .modal-action {
        flex: 1;
    }
}

.genesis-url {
    max-width: 400px;
}

.tokenomics-loader {
    margin: 0 auto 24px;
}


@media only screen and (max-width: 720px) {
    .genesis-url {
        max-width: calc(100vw - 220px)
    }
}
