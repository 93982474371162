.earnings-page {
    .header-section {
        .earning-type-card {
            border: none;
            display: flex;
            flex-direction: column;
            background-image: linear-gradient(to bottom, rgba(var(--dark-green-rgb), 0.05), rgba(var(--red-dark-rgb), 0.05));

            .card-type-indicator {
                width: 10px;
                height: 10px;
                display: block;
                margin-right: 8px;
                border-radius: 5px;
            }

            .card-label {
                margin-bottom: 8px;
                display: flex;
                align-items: center;
            }

            .card-description {
                font-size: 14px;
                color: var(--cream-dark);
            }

            .card-action {
                align-self: flex-start;
                margin-top: 16px;
            }
        }
    }

    .earning-list-header {
        margin: 24px 0 12px;
        line-height: 36px;
        align-items: center;
        display: flex;

        .earning-list-filters {
            .earning-type-select {
                flex: initial;
                white-space: nowrap;
            }
        }
    }
}


