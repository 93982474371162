.rollapps-statistics-container {
    --rollapps-statistics-height: 48px;

    height: var(--rollapps-statistics-height);
    margin-bottom: var(--page-padding);

    .rollapps-statistics-carousel-container {
        overflow: hidden;
        position: absolute;
        left: 0;
        width: 100vw;
        height: var(--rollapps-statistics-height);
    }

    .rollapps-statistics-carousel {
        height: var(--rollapps-statistics-height);
        border-style: solid;
        border-color: var(--border-color-dark);
        border-width: 1px 0 1px 0;
        width: fit-content;
        min-width: 100%;
        background-color: #231f1e;
        padding: 0 var(--page-padding);
        display: flex;
        align-items: center;
        animation: statistics-scroll 5s linear infinite;
    }
}

.statistics-property {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--light-blue);

    .property-label {
        color: var(--text-color-secondary);
        margin-right: 4px;
        white-space: nowrap;
    }

    .statistics-change-container {
        flex-wrap: initial;
        white-space: nowrap;
    }

    &:not(:last-of-type) {
        margin-right: 12px;
    }
}

@media only screen and (max-width: 1180px) {
    .rollapps-statistics-container .rollapps-statistics-carousel {
        animation-duration: 10s;
    }
}

@media only screen and (max-width: 1024px) {
    .rollapps-statistics-container {
        --rollapps-statistics-height: 32px;

        .rollapps-statistics-carousel {
            padding: 0 calc(var(--page-padding) * 2);
            animation-duration: 15s;
        }
    }
}

@media only screen and (max-width: 860px) {
    .rollapps-statistics-container .rollapps-statistics-carousel {
        animation-duration: 20s;
    }
}

@media only screen and (max-width: 720px) {
    .rollapps-statistics-container .rollapps-statistics-carousel {
        animation-duration: 25s;
    }
}

@media only screen and (max-width: 480px) {
    .rollapps-statistics-container .rollapps-statistics-carousel {
        animation-duration: 30s;
    }
}

@keyframes statistics-scroll {
    0% {
        transform: translateX(0);
    }
    10% {
        transform: translateX(0);
    }
    95% {
        transform: translateX(calc(-100% + 100vw));
    }
    100% {
        transform: translateX(calc(-100% + 100vw));
    }
}
