.remove-app-dialog {
    .dialog-title {
        margin-bottom: 16px;
    }

    .dialog-actions {
        margin-top: 24px;
    }

    .remove-button {
        --button-color: rgba(var(--red-rgb), 0.6);
    }

    .dialog-footer {
        margin-top: 24px;
    }
}

