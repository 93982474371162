.da-selector-dialog.da-selector-dialog {
    width: 1024px;
    padding: var(--page-padding) 0;

    .dialog-title {
        width: 100%;
        text-align: center;
        font-size: 24px;
        padding: 0 var(--page-padding);
    }

    .dialog-content {
        margin-top: 12px;
        display: flex;
        flex-direction: column;

        .dialog-subtitle {
            text-align: center;
            padding: 0 var(--page-padding);
            color: var(--text-color-secondary);
        }

        .das-swiper {
            margin: calc(var(--page-padding) + 8px) 0 8px;

            .da-button {
                --button-height: initial;
                --button-icon-size: 64px;
                --button-color-active: rgba(var(--cream-very-dark-rgb), 0.25);

                flex: 1;
                flex-direction: column;
                padding: var(--page-padding);
                border: 1px solid var(--background-color-secondary);

                .da-logo {
                    margin-bottom: 8px;
                }

                .da-properties {
                    width: 100%;
                    margin-top: 16px;
                    padding: 12px 0;
                    border-top: 1px solid var(--border-color);
                    border-bottom: 1px solid var(--border-color);

                    .da-property {
                        display: flex;
                        color: var(--cream);
                        margin: 12px 0;

                        .property-name {
                            flex: 1;
                            color: var(--cream-very-dark);
                            text-align: start;
                        }

                    }
                }

                &.active {
                    border-color: var(--blue);
                }
            }
        }
    }

    .dialog-actions {
        margin: 0 var(--page-padding);
    }
}

@media only screen and (max-width: 720px) {
    .da-selector-dialog.da-selector-dialog .dialog-content .das-swiper .da-button {
        --button-icon-size: 56px;

        .da-logo {
            margin-bottom: 4px;
        }

        .da-properties {
            margin: 8px 0;
            padding: 8px 0;
        }
    }
}

