.network-tokenomics.section {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    .tokenomics-charts-container {
        display: flex;
        width: 100%;
        position: relative;

        .section-part {
            background-color: var(--black);
            flex: 1;
            width: 0;

            &:not(:last-of-type) {
                margin-right: 16px;

                .tokenomics-chart-container {
                    position: relative;

                    &:after {
                        content: '';
                        height: 100%;
                        width: 1px;
                        position: absolute;
                        top: 0;
                        right: -8px;
                        background-color: var(--border-color);
                        display: block;
                    }
                }
            }
        }

        &.vertically {
            flex-direction: column;

            .section-part {
                width: 100%;

                &:not(:last-of-type) {
                    margin-right: 0;
                    margin-bottom: 20px;
                    padding-bottom: 24px;
                    border-bottom: 1px solid var(--border-color);

                    .tokenomics-chart-container:after {
                        display: none;
                    }
                }
            }
        }
    }

    .vesting-schedule-chart-container {
        border-top: 1px solid var(--border-color);
        padding-top: 20px;
        margin-top: 24px;

        .recharts-responsive-container {
            margin-left: -8px;
        }
    }

    .part-title {
        margin-bottom: 16px;
    }

    &.no-data {
        padding: 0;
    }
}

@media only screen and (max-width: 720px) {
    .network-tokenomics.section .vesting-schedule-chart-container .recharts-responsive-container {
        margin-left: initial;
    }
}
