$dialog-width: 540px;

.liquidity-dialog.liquidity-dialog {
    width: $dialog-width;
    display: flex;
    flex-direction: column;

    .dialog-content {
        margin-top: 24px;
    }

    .unbonding-warning {
        margin: 32px 0 -8px;
    }

    .submit-button {
        width: 100%;
        margin-top: 32px;
    }

    .position-header {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        margin-bottom: 32px;

        .position-label {
            color: var(--text-color-secondary);
        }

        .position-shares.type-add {
            height: 30px;
            display: flex;
            align-items: center;
            margin-top: 8px;
            font-size: 24px;
        }
    }

    .liquidity-slider {
        appearance: none;
        width: 100%;
        border-radius: 8px;
        height: 16px;
        position: relative;
        overflow: hidden;
        outline: none;

        @mixin slider-thumb {
            width: 16px;
            height: 16px;
            border-radius: 12px;
            background-color: var(--cream);
            box-shadow: -$dialog-width 0 0 calc($dialog-width - 8px) var(--blue);
            cursor: pointer;
        }

        &::-webkit-slider-thumb {
            appearance: none;
            @include slider-thumb;
        }

        &::-moz-range-thumb {
            @include slider-thumb;
        }

        &::-webkit-slider-runnable-track {
            background-color: var(--background-color-secondary);
        }

        &::-moz-range-track {
            background-color: var(--background-color-secondary);
        }
    }

    .liquidity-slippage-alert {
        margin-top: 24px;
    }

    .liquidity-slippage-input {
        --control-height: 28px;
        --control-padding: 6px;

        font-weight: 600;
    }

    .liquidity-part-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;

        & > * {
            flex: 1;

            &:not(:last-of-type) {
                margin-right: 24px;
            }
        }
    }
}
