.sponsorship {
    display: grid;
    flex-direction: column;
    grid-template-columns: 320px calc(100% - 320px - var(--page-small-padding));
    grid-template-rows: auto auto auto;
    gap: var(--page-small-padding);

    .sponsorship-statistics {
        grid-row: 1;
        grid-column: 1;
    }

    .sponsorship-info-box {
        grid-row: 2;
        grid-column: 1 / span 2;
        margin-top: 8px;
    }

    .vote-list {
        grid-row: 3;
        grid-column: 1 / span 2;
    }

    .distribution-list {
        grid-row: 4;
        grid-column: 1 / span 2;
    }

    .sponsorship-chart-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        grid-row: 1;
        grid-column: 2;

        .chart-spinner, .no-data {
            position: absolute;
            font-size: 16px;
        }

        .sponsorship-chart-legend {
            position: absolute;
            left: var(--page-padding);
            opacity: 0;
            transition: opacity 300ms ease-in-out;

            .legend-item {
                margin: 40px 0;
                display: flex;
                flex-direction: column;

                .legend-item-header {
                    display: flex;
                    white-space: nowrap;

                    .indicator {
                        --color: var(--cream-rgb);

                        width: 18px;
                        height: 18px;
                        border-radius: 6px;
                        display: block;
                        margin-right: 8px;
                        border: 1px solid rgb(var(--color));
                        background-color: rgba(var(--color), 0.5);

                        &.rollapp {
                            --color: var(--light-blue-rgb);
                        }

                        &.pool {
                            --color: var(--light-green-rgb);
                        }
                    }
                }

                .legend-item-property {
                    margin-top: 12px;

                    .property-value {
                        margin-top: 0;
                        font-size: 12px;

                        &.streamed-value {
                            flex-direction: column;
                        }
                    }
                }
            }

            &.visible {
                opacity: 1;
            }
        }
    }
}

@media only screen and (max-width: 1440px) {
    .sponsorship {
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto auto;

        .sponsorship-chart-container {
            grid-row: 2;
            grid-column: 1;
        }

        .sponsorship-info-box {
            grid-row: 3;
            grid-column: 1;
        }

        .vote-list {
            grid-row: 4;
            grid-column: 1;
        }

        .distribution-list {
            grid-row: 5;
            grid-column: 1;
        }
    }
}

@media only screen and (max-width: 720px) {
    .sponsorship {
        .sponsorship-info-box {
            margin-top: 0;
        }

        .sponsorship-chart-container {
            display: none;
        }

        .account-sponsorships-header {
            margin-top: 0;
        }
    }
}
