.statistics-change-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    transition: all 5000ms ease;

    .left-space {
        width: 6px;
        display: block;
    }

    &.right {
        justify-content: flex-end;

        .left-space {
            display: none;
        }

        .statistics-change {
            margin-left: 6px;
        }
    }

    &.red {
        color: var(--red);
        transition-duration: 0ms;
    }

    &.green {
        color: var(--green);
        transition-duration: 0ms;
    }
}

.statistics-change {
    --change-color: var(--red);
    --caret-rotation: 180deg;

    display: flex;
    align-items: center;
    color: var(--change-color);
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;

    .caret-icon {
        width: 12px;
        height: 12px;
        margin-right: 2px;
        transform: rotate(var(--caret-rotation));

        svg path {
            fill: var(--change-color);
        }
    }

    &.small {
        font-size: 11px;
        margin-left: -2px;

        .caret-icon {
            width: 10px;
            height: 10px;
        }
    }

    &.positive {
        --change-color: var(--green);
        --caret-rotation: 0deg;
    }
}

