.confirm-dialog.confirm-dialog {

    .confirm-dialog-title {
        display: flex;
        align-items: center;

        .warning-icon {
            --color: var(--orange);

            margin-right: 8px;
            width: 24px;
            height: 24px;
        }

        &.warning {
            color: var(--orange);
        }
    }

    .confirm-dialog-content {
        margin-bottom: var(--page-padding);

        &.haveTitle {
            margin-top: var(--page-small-padding);
        }
    }
}
