.spinner {
    --spinner-color: var(--text-color-secondary);

    border: 3px solid transparent;
    border-radius: 50%;
    border-top-color: var(--spinner-color);
    border-bottom-color: var(--spinner-color);
    width: 24px;
    height: 24px;
    display: block;
    animation: spin 1s linear infinite;

    &.xs {
        width: 14px;
        height: 14px;
        border-width: 2px;
    }

    &.small {
        width: 16px;
        height: 16px;
        border-width: 2px;
    }

    &.large {
        width: 28px;
        height: 28px;
    }

    &.secondary {
        --spinner-color: var(--black);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
