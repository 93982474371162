.controls-container {
    display: flex;
    padding: 0;
    align-items: center;

    & > .form-control, & > .button {
        &:not(:first-child) {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            margin-left: -1px;
        }

        &:not(:last-child) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
    }

    & > .form-control {
        flex: 1;
    }

    &.error {
        & > .form-control, & > .button {
            border-color: var(--red);
        }
    }
}
