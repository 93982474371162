.rollapp-cards-container {
    --card-width: calc(100% / 3);
    --card-space: 12px;

    display: flex;
    flex-wrap: wrap;
    margin: calc(var(--card-space) * -1);
    position: relative;

    .no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 0;
        width: 100%;
        color: var(--text-color-secondary);
    }

    .rollapp-card {
        border-radius: 6px;
        margin: var(--card-space);
        width: calc(var(--card-width) - var(--card-space) * 2);
        transition: background-color ease-in-out 150ms;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        background-color: var(--black);
        border: 1px solid var(--border-color-dark);

        .card-header {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: content-box;
            min-height: 56px;
            max-height: 56px;
            padding: 16px 12px;
            transition: background-color ease-in-out 150ms;

            .simple-line-chart {
                margin-right: -4px;
            }

            .simple-chart-title {
                position: absolute;
                top: 6px;
                right: 6px;
                font-size: 11px;
                background-color: rgba(var(--black-rgb), 0.5);
                color: rgba(var(--cream-very-dark-rgb), 0.7);
            }

            .rollapp-badges {
                position: absolute;
                top: -10px;
                left: -10px;
                display: flex;
                align-items: center;

                & > *:not(:last-of-type) {
                    margin-right: 6px;
                }
            }

            .rollapp-summary {
                flex: 1;

                .rollapp-details {
                    flex: 1;
                    width: 0;

                    .rollapp-name-container {
                        flex-wrap: initial;

                        .rollapp-name {
                            max-width: initial;
                            display: block;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }

        .card-tagline {
            color: var(--cream-dark);
            max-width: calc(100% - 120px);
            padding: 0 12px 12px;
            margin-top: -8px;
            font-size: 13px;
        }

        .card-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 16px 0;
            border-top: 1px solid var(--border-color);

            .card-properties {
                font-size: 12px;
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: space-around;

                .property {
                    padding: 0 8px;
                    flex: 1;
                    height: 100%;
                    justify-content: center;

                    .property-label, .property-value {
                        justify-content: center;
                        text-align: center;

                        .statistics-change-container {
                            justify-content: center;
                        }
                    }

                    &:not(:last-of-type) {
                        border-right: 1px solid var(--border-color);
                    }

                    &:first-of-type {
                        min-width: 25%;
                        flex: initial;
                    }
                }

                .staking-apr-value {
                    font-weight: bold;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .dym-native-icon {
                    width: 18px;
                    height: 18px;
                }

                .iro-bonding-curv-progress {
                    min-width: 76px;

                    .bonding-curv-progress {
                        height: 18px;
                    }

                    .bonding-curv-progress-value {
                        font-size: 12px;
                    }
                }

                .iro-time-property-value {
                    color: var(--gold);
                }
            }
        }

        &:hover {
            background-color: var(--black-light);

            .simple-chart-title {
                background-color: rgba(var(--black-light-rgb), 0.5);
            }
        }

        &.glow {
            box-shadow: 0 0 8px 0 rgba(var(--glow-color), 0.6);
            outline: 1px solid rgba(var(--glow-color), 0.4);
        }
    }
}

@media only screen and (max-width: 1280px) {
    .rollapp-cards-container {
        --card-width: calc(50%);
    }
}

@media only screen and (max-width: 860px) {
    .rollapp-cards-container {
        --card-width: calc(100%);
    }
}

@media only screen and (max-width: 720px) {
    .rollapp-cards-container .rollapp-card {
        .card-tagline {
            max-width: initial;
        }

        .card-content {
            padding: 12px 0;

            .card-properties .property {
                padding: 0 6px;

                &:first-of-type {
                    flex: 1;
                    min-width: initial;
                }
            }
        }
    }
}
