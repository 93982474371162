.network-dashboard {
    display: flex;
    position: relative;

    .dashboard-network-header {
        margin-top: -24px;
    }

    .dashboard-content {
        flex: 1;
        width: 100%;
    }

    .dashboard-tab-bar .dashboard-tab {
        margin-top: var(--page-padding);
    }

    .asset-tab-label {
        display: flex;
        align-items: center;

        &.trade {
            color: var(--light-green);

            .trade-icon {
                --color: var(--light-green);

                margin-right: 6px;
                max-width: 24px;
                max-height: 24px;
            }

            &.disabled {
                opacity: 0.5;
            }

            &.iro {
                color: rgb(var(--gold-rgb));

                .trade-icon {
                    --color: rgb(var(--gold-rgb));
                }
            }
        }
    }

    .subtitle {
        margin: 8px 0;
        align-items: center;
        height: 40px;
        display: flex;
    }
}

@media only screen and (min-width: 1921px) {
    .network-dashboard.page {
        max-width: 1920px;
    }
}

@media only screen and (max-width: 720px) {
    .network-dashboard .dashboard-network-header {
        margin-top: -16px;
    }
}
