.rollapps-table-container {
    overflow: auto;

    .table {
        --row-height: 72px;


        .menu-action {
            margin-left: 8px;
        }

        .simple-chart-column {
            padding-right: 16px;
        }
    }

    .no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 0;
        color: var(--text-color-secondary);
    }

    .staking-apr-value {
        font-weight: bold;

        &:hover {
            text-decoration: underline;
        }
    }

    .vm-logo {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }

    .name-column {
        width: 20%;
    }

    .iro-bonding-curv-progress {
        width: 120px;
    }

    .iro-time-column {
        .iro-status-indicator {
            margin-right: 4px;
        }

        &.launchable {
            color: var(--orange);
        }
    }

    .rollapp-can-launch-property-value.property-value {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin: 2px 0 -4px;
        color: var(--orange);
    }

    .market-cap-column {
        .mc-warning-icon {
            --color: var(--orange);

            width: 15px;
            height: 15px;
            margin-right: 4px;
        }

        &.invalid {
            font-style: italic;
        }
    }

    &.tokenless .name-column {
        width: 25%;
    }
}

@media only screen and (max-width: 1680px) {
    .rollapps-table-container .name-column {
        width: 25%;
    }
}

@media only screen and (max-width: 720px) {
    .rollapps-table-container .table {
        font-size: 12px;

        .dym-native-badge {
            --padding: 4px 6px;

            .dym-native-badge-icon {
                min-width: 14px;
                min-height: 14px;
                margin-right: 4px;
            }
        }
    }
}
