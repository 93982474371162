@use 'styles/theme';
@use 'styles/fonts';
@use 'styles/elements';
@import 'vanilla-jsoneditor/themes/jse-theme-dark.css';

html, body, #root {
    --color-schema: dark;
    --html-background-image: linear-gradient(to bottom, var(--background-color), black);
    --body-background-image: linear-gradient(to right, rgba(70, 60, 58, 0.08) 2.5px, transparent 2.5px),
    linear-gradient(to bottom, rgba(70, 60, 58, 0.08) 2.5px, transparent 2.5px);

    line-height: 1.25;
    width: 100%;
    height: 100%;
    margin: 0;
    color-scheme: var(--color-schema);
    scroll-behavior: smooth;

    &:has(.connect-container) {
        --color-schema: initial;
        --html-background-image: none;
        --body-background-image: none;
    }
}

.theme-dark:root {
    --tv-color-platform-background: #d1c4e9;
    --tv-color-pane-background: rgb(251, 223, 244);
    --tv-color-toolbar-button-background-hover: rgb(244, 143, 177);
    --tv-color-toolbar-button-background-expanded: rgb(244, 143, 177);
    --tv-color-toolbar-button-background-active: rgb(249, 185, 233);
    --tv-color-toolbar-button-background-active-hover: rgb(244, 143, 177);
    --tv-color-toolbar-button-text: rgb(136, 24, 79);
    --tv-color-toolbar-button-text-hover: rgb(74, 20, 140);
    --tv-color-toolbar-button-text-active: red;
    --tv-color-toolbar-button-text-active-hover: red;
    --tv-color-item-active-text: rgb(6, 255, 6);
    --tv-color-toolbar-toggle-button-background-active: red;
    --tv-color-toolbar-toggle-button-background-active-hover: magenta;
    --tv-color-toolbar-divider-background: rgb(251, 223, 244);
    --tv-color-toolbar-save-layout-loader: rgb(134, 137, 147);

    --tv-color-popup-background: rgb(241, 188, 225);
    --tv-color-popup-element-text: rgb(136, 24, 79);
    --tv-color-popup-element-text-hover: rgb(74, 20, 140);
    --tv-color-popup-element-background-hover: rgb(244, 143, 177);
    --tv-color-popup-element-divider-background: rgb(251, 223, 244);
    --tv-color-popup-element-secondary-text: rgb(74, 20, 140);
    --tv-color-popup-element-hint-text: rgb(74, 20, 140);
    --tv-color-popup-element-text-active: rgb(6, 6, 255);
    --tv-color-popup-element-background-active: red;
    --tv-color-popup-element-toolbox-text: rgb(136, 24, 79);
    --tv-color-popup-element-toolbox-text-hover: rgb(74, 20, 140);
    --tv-color-popup-element-toolbox-text-active-hover: rgb(74, 20, 140);
    --tv-color-popup-element-toolbox-background-hover: rgb(222, 89, 132);
    --tv-color-popup-element-toolbox-background-active-hover: magenta;
}

html:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: var(--html-background-image);
}

body:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: 60px 45px;
    background-image: var(--body-background-image);
}

body, button, input, textarea, select, option {
    font-family: Inter, Helvetica Neue, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    font-weight: 500;
    color: var(--cream);
}

input[type=datetime-local], input[type=datetime] {
    &::-webkit-calendar-picker-indicator {
        filter: invert(0.5);
        cursor: pointer;
        transform: scale(1.2);
    }
}

a {
    color: var(--cream);
    text-decoration: none;
}

p {
    margin: 0;
    line-height: 18px;
    word-break: break-word;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    line-height: 1.75;
    margin: 0;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}

* {
    box-sizing: border-box;
}

label {
    font-size: 16px;
    color: var(--text-color-secondary);
}

small {
    font-size: 12px;
    font-weight: 500;
}

ul.list {
    margin: 10px 0 0;
    padding-left: 20px;

    li {
        margin: 8px 0;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.markdown {
    p, h1, h2, h3, h4, h5, h6 {
        margin: revert;
        font-size: revert;
    }

    a {
        text-decoration: revert;
    }

    code {
        color: var(--cream-dark);
        font-style: italic;
    }

    ul, ol {
        margin: 24px 0;
        background-color: var(--black-dark);
        border-radius: 6px;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-right: 16px;

        li {
            padding: 12px 0;
        }
    }
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex-wrap {
    flex-wrap: wrap;
}

.secondary-text {
    color: var(--text-color-secondary);
}

.primary-text {
    color: var(--text-color);
}

@for $i from 0 through 20 {
    .flex-#{$i} {
        flex: $i;
    }
}

@for $i from 100 through 900 {
    .font-weight-#{$i} {
        font-weight: $i;
    }
}


@media only screen and (max-width: 1024px) {
    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 20px;
    }

    h4 {
        font-size: 16px;
    }

    h5 {
        font-size: 14px;
    }

    h6 {
        font-size: 12px;
    }

    .markdown {
        ul, ol {
            padding-left: 20px;
        }
    }
}
