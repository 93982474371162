.quick-auth-dialog.quick-auth-dialog {
    .dialog-title {
        display: flex;
        align-items: center;

        .icon-container {
            --color: var(--orange);

            width: 24px;
            height: 24px;
            margin-right: 6px;
        }
    }

    .dialog-content {
        display: flex;
    }

    .configuration-section {
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 32px;
        padding-right: 32px;
        width: 340px;
        border-right: 1px solid var(--border-color-dark);

        .quick-auth-description {
            line-height: 24px;
            color: var(--cream-dark);

            .description-info-indicator {
                display: inline-block;
                height: 15px;
                margin-left: 4px;
            }
        }

        .connect-action {
            margin-top: 24px;
            width: 100%;

            &.revoke:not(.disabled) {
                --background-color-inverse: var(--red-dark);
                --button-text-color: var(--cream);
                --button-color-hover: rgba(var(--red-dark-rgb), 0.7);
                --button-color-active: rgba(var(--red-dark-rgb), 0.5);
            }
        }
    }

    .qr-section {
        display: flex;
        flex-direction: column;
        filter: blur(6px);

        .qr-code-spinner-container {
            height: 256px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .qr-label {
            color: var(--cream-dark);
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 12px;
            display: flex;
            flex-direction: column;
            text-align: center;

            small {
                margin-top: 2px;
            }
        }

        .qr-code {
            padding: 8px;
            background-color: var(--cream);
        }

        .confirmation-pin-code-container {
            .control-label-container {
                justify-content: center;
            }

            .confirmation-pin-code {
                justify-content: center;

                .input {
                    color: var(--cream);
                    text-align: center;
                    font-size: 24px;
                    width: 120px;
                    letter-spacing: 4px;
                }
            }
        }


        &.visible {
            filter: none;
        }
    }

    .control-container {
        margin: 16px 0 0;
    }

    label {
        font-size: 14px;
    }
}
