.sequencer-section {

    .permissionless-switch .space {
        display: none;
    }

    .info-box-container {
        overflow: hidden;
        margin: 32px 0 20px;
        transition: all 300ms ease-in-out;

        .alert-container {
            transition: all 300ms ease-in-out;
        }

        .link {
            --link-color-normal: var(--cream);

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .sequencer-section .success-box.visible {
          margin-top: 24px;
    }
}
