.badge {
    --badge-color: var(--cream-rgb);
    --border-radius: 30px;
    --rounder-border-radius: 7px;
    --padding: 4px 7px;
    --font-size: 14px;

    line-height: initial;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    padding: var(--padding);
    color: rgba(var(--badge-color));
    border: 1px solid rgba(var(--badge-color), 0.22);
    background-color: rgba(var(--badge-color), 0.15);
    font-size: var(--font-size);
    border-radius: var(--border-radius);
    outline: none;
    margin: 0;

    img, svg {
        width: 18px;
        height: 18px;
        max-width: 18px;
        max-height: 18px;
        margin-right: 4px;
    }

    &.no-wrap {
        white-space: nowrap;
    }

    &.rounded {
        --border-radius: var(--rounder-border-radius);
    }

    &.small {
        --rounder-border-radius: 6px;
        --padding: 2px 6px;
        --font-size: 12px;
    }

    &.large {
        --rounder-border-radius: 8px;
        --padding: 6px 10px;
    }
}
