.iro-curve-section {
    .controls-row.curve-charts-container {
        margin: 0 -16px;

        .curve-chart-container {
            --color: var(--cream-very-dark);

            position: relative;
            margin-right: 0;
            border: 1px solid transparent;
            border-radius: 4px;
            width: 0;
            padding: 0 6px 0 16px;
            transition: border-color 150ms ease-in-out;

            .chart-grid {
                stroke: rgba(var(--cream-rgb), 0.1);
            }

            .area-background {
                stop-color: var(--color);
                transition: stop-color 150ms ease-in-out;
                z-index: 0;
            }

            .area-chart .recharts-area-curve {
                stroke: var(--color);
                z-index: 0;
                stroke-width: 2;
                transition: stroke 150ms ease-in-out;
            }

            .curve-chart-header {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
            }

            &.active {
                --color: var(--green);
            }

            &.disabled {
                opacity: 0.4;
            }

            &:not(.disabled) {
                .recharts-wrapper {
                    cursor: pointer !important;
                }

                &:hover {
                    border-color: rgba(var(--cream-rgb), 0.1)
                }
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .iro-curve-section .controls-row.curve-charts-container {
        flex-wrap: wrap;
        margin-bottom: -12px;

        .curve-chart-container {
            min-width: 50%;
            margin-bottom: 12px;
        }
    }
}

@media only screen and (max-width: 720px) {
    .iro-curve-section .controls-row.curve-charts-container {
        margin: 0 -12px;

        .curve-chart-container {
            width: 100%;
            padding: 0 2px 0 12px;
            margin-bottom: 16px;
        }
    }
}
