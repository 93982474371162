.highlight-card.section.small {
    padding: 12px;
    background-image: linear-gradient(to bottom, rgba(var(--dark-green-rgb), 0.05), rgba(var(--red-dark-rgb), 0.05));

    .card-label {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        opacity: 0.7;

        .icon-container {
            max-width: 20px;
        }
    }

    .rollapp-row {
        display: flex;
        align-items: center;
        height: 40px;
        margin: 0 -12px;
        padding: 0 12px;
        cursor: pointer;

        .asset-logo {
            width: 24px;
            height: 24px;
            border-radius: 12px;
            object-fit: cover;
        }

        .rollapp-name-container {
            flex: 1;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-left: 8px;
            width: calc(50% - 24px);

            .rollapp-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-right: 8px;
            }

            .asset-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 12px;
                margin-right: 8px;
                color: var(--text-color-secondary);
            }
        }

        &:hover {
            background-color: rgba(var(--cream-rgb), 0.05);
        }
    }

    &.activity {
        overflow: hidden;

        .trade-row {
            display: flex;
            align-items: center;
            height: 40px;
            opacity: 1;
            margin: 0 -12px;
            padding: 0 12px;
            transition: opacity 300ms ease-out, transform 300ms ease-out;

            .asset-logo {
                width: 24px;
                height: 24px;
                margin-right: 8px;
                border-radius: 12px;
                object-fit: cover;
            }

            .trade-type-badge {
                margin-left: 4px;
            }

            .trade-token {
                margin-right: 8px;
            }

            &.new {
                opacity: 0;
                transform: translateX(-100%);
            }

            &:hover {
                cursor: pointer;
                background-color: rgba(var(--cream-rgb), 0.05);
            }
        }
    }
}

.swiper-container {
    position: relative;

    .pause-button {
        position: absolute;
        top: 8px;
        right: 10px;
    }

    @media only screen and (max-width: 480px) {
        .highlight-card .rollapp-row .rollapp-name-container .asset-name {
            max-width: 76px;
        }
    }
}
