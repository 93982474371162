.get-tokens-section.get-tokens-section {
    padding: 16px var(--page-padding);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .get-tokens-header {
        color: var(--text-color-secondary);
    }

    .get-tokens-button {
        margin-left: 8px;
        white-space: nowrap;
        padding: 4px 16px;
    }
}

