.swiper-container {
    margin: 0 calc(var(--page-padding) * -1);

    .swiper {
        width: 100%;
        overflow: auto;
        padding: 0 calc(var(--page-padding) - (var(--page-small-padding) / 2));
        scroll-snap-type: x mandatory;
        -ms-overflow-style: none;
        scrollbar-width: none;
        display: flex;

        &::-webkit-scrollbar {
            display: none;
        }

        & > * {
            float: left;
            scroll-snap-align: center;
            scroll-snap-stop: normal;
            min-width: calc(var(--item-width) + var(--page-small-padding));
            max-width: calc(var(--item-width) + var(--page-small-padding));
            margin: 0 calc(var(--page-small-padding) / 2);
        }
    }

    .page-dots {
        list-style: none;
        display: flex;
        justify-content: center;
        margin: 8px 0;
        padding: 0;

        .page-dot {
            width: 14px;
            height: 14px;
            cursor: pointer;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                width: 6px;
                height: 6px;
                border-radius: 4px;
                background-color: rgba(var(--cream-very-dark-rgb), 0.7);
                top: 4px;
                left: 4px;
            }

            &.active:after {
                background-color: var(--cream);
            }
        }

        &.hide {
            opacity: 0;
        }
    }

    &.show-edges .swiper > * {
        min-width: calc(var(--item-width) - var(--page-small-padding));
        max-width: calc(var(--item-width) - var(--page-small-padding));
    }
}

