.icon-container {
    --color: var(--text-color);

    svg {
        height: 100%;
        width: 100%;
        display: block;
    }

    &.icon-color-fill > svg {
        path, g {
            fill: var(--color);
        }
    }

    &.icon-color-stroke > svg {
        path, g {
            stroke: var(--color);
        }
    }

    &.secondary {
        --color: var(--text-color-secondary);
    }
}
