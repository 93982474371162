.action-section.section {
    display: flex;
    flex-direction: column;

    .section-header {
        justify-content: space-between;
        margin-bottom: 0;

        .section-header-text {
            max-width: 800px;

            p {
                margin-top: 8px;
                color: var(--text-color-secondary);
            }
        }
    }

    .transaction-fees-container {
        margin-top: 24px;
    }

    .action-button {
        margin-left: 48px;
    }
}

@media only screen and (max-width: 720px) {
    .action-section.section {
        .section-header {
            flex-direction: column;
            align-items: flex-start;
        }

        .transaction-fees-container {
            margin-top: 16px;
        }

        .action-button {
            margin-top: 16px;
            width: 100%;
            margin-left: 0;
        }
    }
}


