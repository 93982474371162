.address-container {
    display: flex;
    align-items: center;
    padding: 4px 0;
    position: relative;

    &.small {
        --button-icon-size: 18px;

        font-size: 14px;
    }

    &.canCopy {
        color: var(--text-color-secondary);
        cursor: pointer;

        &.isMenuOpen, &:hover {
            color: var(--text-color);
        }
    }
}
