.trade-list-container {
    --row-height: 42px;

    overflow: auto;
    max-height: calc(10 * var(--row-height));

    .trade-list {
        width: 100%;

        .trade-row {
            transition: opacity 300ms ease-in-out;
        }
    }

    .address-badge {
        margin-left: 6px;
        font-weight: bold;
    }

    .no-data {
        display: flex;
        height: 64px;
        align-items: center;
        justify-content: center;
        color: var(--text-color-secondary);
    }
}

@media only screen and (max-width: 720px) {
    .trade-list-container .trade-list {
        font-size: 12px;
    }
}


