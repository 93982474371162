.save-user-dialog.save-user-dialog {
    width: 360px;

    .control-container:first-of-type {
        margin-top: 20px;
    }

    .control-label-container label {
        font-size: 14px;
    }

    .avatar-uploader {
        height:128px;
        justify-content: center;

        .image-preview {
            width: 128px;
        }
    }
}
