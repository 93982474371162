.bonding-curv-progress-container {
    position: relative;
    width: 100%;
    min-width: 120px;
    display: flex;
    align-items: center;

    .bonding-curv-progress {
        border: none;
        border-radius: 10px;
        overflow: hidden;
        width: 100%;
        height: 20px;

        &::-webkit-progress-bar {
            background-color: rgba(var(--cream-very-dark-rgb), 0.25);
        }

        &::-webkit-progress-value {
            background-color: var(--light-green);
        }

        &::-moz-progress-bar {
            background-color: var(--light-green);
        }
    }

    .bonding-curv-progress-value {
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 14px;
    }
}
