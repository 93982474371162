.common-token {
    background-color: transparent;

    &.selected {
        background-color: rgba(var(--cream-rgb), 0.1);
    }

    &:not(:disabled) {
        cursor: pointer;

        &:hover {
            background-color: rgba(var(--cream-rgb), 0.05);
        }
    }

    &:disabled {
        opacity: 0.3;
    }

    &:not(:last-of-type) {
        margin-right: 8px;
    }

    .currency-logo {
        max-width: 20px;
        max-height: 20px;
        width: 20px;
        height: 20px;
        object-fit: cover;
        border-radius: 10px;
        margin-right: 6px;
    }

    .currency-name-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .currency-network-domain {
            font-size: 12px;
            color: var(--text-color-secondary);
            line-height: 12px;
        }
    }
}
