.post-comment-section {
    background-color: var(--black-dark);
    padding: var(--page-small-padding);
    display: flex;
    flex-direction: column;

    .comment-body-container {
        height: 0;
        overflow: hidden;
        transition: height 300ms ease-in-out;
        display: flex;
        flex-direction: column;
        position: relative;

        .user-avatar, .fallback-user-avatar {
            width: 24px;
            height: 24px;
            border-radius: 12px;
            object-fit: cover;
            margin-right: 8px;
        }

        .close-icon {
            margin-right: -6px;
        }

        .comment-body-textarea {
            border: none;
            padding: 8px 0 16px;
            flex: 1;

            .text-area {
                line-height: 20px;
                height: 120px !important;
                display: block;
            }
        }

        .sign-in-button {
            --icon-button-icon-size: 24px;

            margin-left: -4px;
        }

        .comment-image {
            width: 100%;
            max-height: 256px;
            object-fit: contain;
            margin-bottom: 16px;
        }

        .remove-image-button {
            --button-text-color: var(--black-light);

            position: absolute;
            right: 4px;
            margin-top: 4px;
            background-color: var(--cream);

            &:hover {
                --button-text-color: black;
            }
        }
    }

    .comment-actions {
        display: flex;
        align-items: center;

        .post-button {
            flex: 1;
            padding: 4px 0;
            white-space: nowrap;
            overflow: hidden;
        }

        .expanded-actions {
            flex: 0;
            width: 0;
            display: flex;
            overflow: hidden;
            transition: all 300ms ease-in-out;

            .button.icon {
                --icon-button-icon-size: 24px;

                margin-right: 8px;
            }
        }
    }

    &.expanded .comment-actions .expanded-actions {
        width: initial;
        flex: 2;
    }
}

@media only screen and (max-width: 1024px) {
    .post-comment-section {
        padding: var(--page-padding);

        .comment-body-container {
            .comment-body-textarea {
                padding: 8px 0;

                .text-area {
                    height: 80px !important;
                    min-height: 80px;
                }
            }

            .comment-image {
                margin-bottom: 8px;
            }
        }
    }
}
