.simple-line-chart {
    --chart-line-color: var(--red);
    
    .recharts-line path {
        stroke: var(--chart-line-color);
    }
    
    &.positive {
        --chart-line-color: var(--green);
    }
}
