.splashscreen {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: relative;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: var(--top-bar-height);
        padding: 0 var(--page-padding);
        z-index: 100;

        .logo {
            width: 28px;
            height: 28px;
            margin-right: 16px;
            display: inline-block;
        }
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .header-logo {
            height: 80px;
            margin-bottom: 36px;
        }

        h1 {
            text-align: center;
            font-size: 72px;
            line-height: 80px;
            margin-bottom: 36px;
            font-family: TWKEverettMedium, 'Helvetica Neue', sans-serif;
        }

        .subtitle {
            font-size: 24px;
            text-align: center;
            width: 720px;
            padding: 0 48px;
            line-height: 35px;
            color: var(--cream-dark);
        }
    }

    .footer {
        min-height: var(--footer-height);
        display: flex;
        width: 100%;
        z-index: 10000;
        padding: var(--page-padding);
        justify-content: center;
        align-items: center;

        .social-link {
            width: 20px;
            display: inline-block;

            &:not(:first-of-type) {
                margin-left: 16px;
            }
        }
    }

    .grain-background {
        position: absolute;
        opacity: 0.3;
        height: 100% !important;
        object-fit: cover;
        min-width: calc(100vw + 200px);
        animation: noise-slide 10s linear infinite;
    }
}

@keyframes noise-slide {
    from {
        transform: translateX(-200px);
    }
    to {
        transform: translateX(0);
    }
}

@media only screen and (max-width: 720px) {
    .splashscreen .content {
        h1 {
            white-space: normal;
            font-size: 48px;
            margin-bottom: 40px;
            line-height: 52px;
        }

        .header-logo {
            height: 52px;
            margin-bottom: 24px;
        }
    }
}

@media only screen and (max-width: 480px) {
    .splashscreen .header {
        min-height: 56px;
    }
}
