.network-apps {
    --card-image-size: 108px;

    .app-card.section {
        width: 100%;
        display: flex;
        height: var(--card-image-size);
        cursor: pointer;
        background-color: var(--black-dark);
        transition: transform 150ms ease-in-out;

        .app-image {
            border-radius: 6px;
            margin: -7px;
            height: calc(var(--card-image-size) - 12px);
            width: calc(var(--card-image-size) - 12px);
            min-width: calc(var(--card-image-size) - 12px);
            display: block;
            object-fit: cover;
        }

        .app-content {
            margin-left: 24px;
            display: flex;
            flex-direction: column;

            .app-name {
                margin-bottom: 8px;
                line-height: 20px;
            }

            .app-description {
                color: var(--cream-dark);
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 17px;
                max-height: 51px;
                word-break: break-word;
            }
        }

        &:not(:first-of-type) {
            margin-top: var(--page-small-padding);
        }

        &:hover {
            transform: scale(1.02);
        }
    }
}
