.multiple-network-selector {
    width: 100%;

    .trigger-arrow {
        z-index: 10;
        padding: 8px 0;
    }
}

.multiple-network-selector-options, .multiple-network-selector {
    .network-logo {
        max-width: 22px;
        max-height: 22px;
        border-radius: 11px;
        margin-right: 12px;
        object-fit: cover;
    }

    .network-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .network-address {
        margin-left: 8px;
    }

    .import-to-wallet-button {
        --button-padding: 4px 10px;
        --button-height: 28px;
    }

    &.small .network-logo {
        max-width: 20px;
        max-height: 20px;
        border-radius: 10px;
        margin-right: 8px;
    }
}
