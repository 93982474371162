.earning-list-container {
    overflow: auto;

    .no-data {
        display: flex;
        height: 64px;
        align-items: center;
        justify-content: center;
        color: var(--text-color-secondary);
    }

    .earning-list {
        --row-height: 56px;

        .time-column {
            padding-left: var(--page-padding);
        }

        .earning-row.summary {
            --row-height: 44px;
            font-weight: bold;
            font-size: 16px;

            .table-column {
                border-top: 1px solid var(--border-color);
                background-color: var(--black-dark) !important;
            }
        }

        .amount-column {
            width: 40%;

            .token-badge {
                margin-right: 4px;
                cursor: pointer;

                .token-name {
                    font-size: 12px;
                    color: var(--cream-dark);
                }

                &:not(:hover) {
                    background-color: transparent;
                }
            }

            .token-logo {
                width: 16px;
                height: 16px;
                margin-right: 4px;
                border-radius: 8px;
                object-fit: cover;
            }

            .more-tokens-tooltip {
                display: flex;
                flex-direction: column;

                .more-tooltip-token:not(:last-of-type) {
                    margin-bottom: 8px;
                }
            }
        }

        .entity-column .unknown-entity .network-logo {
            width: 30px;
            height: 30px;
            border-radius: 15px;
            object-fit: cover;
            z-index: 1;
            margin: 0 13px 0 5px;
            opacity: 0.4;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .earning-list-container .earning-list .amount-column {

        .amount-column-content {
            flex-direction: column;
            align-items: flex-start;

            .token-badge {
                margin: 4px 0;
            }
        }
    }
}

