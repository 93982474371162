.network-sponsorship {
    --color-rgb: var(--blue-rgb);

    position: relative;
    min-height: 74px;

    .sponsorship-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sponsorship-header {
        align-items: center;
        justify-content: center;
        display: flex;
        margin-bottom: 12px;

        .dymension-logo {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            display: inline-block;
            position: relative;
        }
    }

    .voting-power-progress-bar {
        border-radius: 6px;
        width: 100%;
        height: 32px;
        position: relative;
        background-color: rgba(var(--color-rgb), 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .voting-power-weight {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            min-width: 1px;
            z-index: 1;
            background-color: rgb(var(--color-rgb));
        }

        .voting-power-value {
            position: absolute;
            z-index: 2;
            font-weight: bold;
            font-size: 16px;
        }
    }

    .sponsorship-disabled {
        color: var(--text-color-secondary);
        text-align: center;
    }
}
