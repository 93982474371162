.overlay-container {
    position: fixed;
    z-index: 1000;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .overlay-pane {
        position: absolute;
        pointer-events: auto;
        opacity: 0;
        width: fit-content;
        overflow: auto;
        max-height: 90%;
        max-width: calc(100% - 24px);

        &.visible {
            opacity: 1;
        }
    }

    &.dim {
        background-color: #000000aa;
        pointer-events: initial;
    }

    &.relative {
        position: relative;

        & > .overlay-pane {
            position: relative;
            top: initial !important;
            left: initial !important;
            max-width: initial !important;
            width: 100%;
            height: 100%;
            max-height: initial;
        }
    }
}
