.stepper {
    display: flex;
    justify-content: space-between;

    .step {
        --color-rgb: var(--cream-rgb);

        flex: 1;

        .step-progress-container {
            display: flex;
            align-items: center;

            .step-indicator {
                display: inline-block;
                width: 32px;
                height: 32px;
                border-radius: 32px;
                text-align: center;
                line-height: 28px;
                font-size: 24px;
                position: relative;
                color: var(--cream);
                border: 2px solid transparent;
                transition: all 500ms ease-out;

                &:after {
                    content: '';
                    z-index: -1;
                    position: absolute;
                    width: 32px;
                    height: 32px;
                    left: -2px;
                    top: -2px;
                    display: block;
                    border-radius: 32px;
                    background-color: rgb(var(--color-rgb), 0.3);
                    transition: all 500ms ease-out;
                }
            }

            .step-progress {
                position: relative;
                flex: 1;
                height: 2px;
                border-radius: 4px;
                overflow: hidden;
                margin: 0 16px;
                background-color: rgba(var(--color-rgb), 0.3);
                transition: all 500ms ease-out;

                &:after {
                    content: '';
                    height: 100%;
                    width: 0;
                    display: block;
                    transition: all 500ms ease-out;
                    background-color: rgb(var(--color-rgb));
                }
            }
        }

        .step-number {
            margin-top: 10px;
            font-size: 12px;
            font-weight: 600;
            color: var(--text-color-secondary);
        }

        .step-name {
            margin-top: 6px;
            font-size: 15px;
            color: rgba(var(--cream-rgb), 0.7);

            &.visible {
                color: var(--cream);
            }
        }

        &.passed {
            --color-rgb: var(--blue-rgb);

            .step-indicator {
                border-color: rgb(var(--color-rgb));

                &:after {
                    background-color: rgb(var(--color-rgb));
                }
            }

            .step-progress:after {
                width: 100%;
            }
        }

        &.current {
            --color-rgb: var(--blue-rgb);

            .step-indicator {
                border-color: rgb(var(--color-rgb));
                color: transparent;

                &:after {
                    scale: 0.5;
                    background-color: rgb(var(--color-rgb));
                }
            }

            .step-progress:after {
                width: 50%;
            }
        }

        &.disabled {
            .step-indicator {
                --color-rgb: var(--cream-very-dark-rgb);
            }

            .step-name, .step-number {
                opacity: 0.5;
            }
        }

        &.visible {
            --color-rgb: var(--cream-rgb);

            .step-indicator {
                color: var(--blue);
            }
        }

        &.clickable {
            cursor: pointer;
        }

        &:last-of-type {
            flex: initial;

            .step-progress-container .step-progress {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .stepper {


        .step {

            .step-progress-container {
                .step-indicator {
                    width: 24px;
                    height: 24px;
                    border-radius: 24px;
                    line-height: 20px;
                    font-size: 20px;

                    &:after {
                        width: 24px;
                        height: 24px;
                        border-radius: 24px;
                    }
                }

                .step-progress {
                    margin: 0 8px;
                }
            }

            .step-number {
                font-size: 11px;
            }

            .step-name {
                font-size: 12px;
                padding-right: 4px;
                width: min-content;
            }

            &:last-of-type {
                flex: 0;

                .step-name {
                    padding-right: 0;
                }
            }
        }
    }
}

