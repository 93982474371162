.quick-auth-action {
    cursor: pointer;
    position: fixed;
    top: calc(56px +  var(--test-env-bar-height));
    font-weight: 600;
    font-size: 12px;
    right: var(--page-padding);
    height: 32px;
    width: 148px;
    min-width: 118px;
    background-color: rgba(var(--black-rgb), 0.8);
    border-radius: 8px;
    border: 1px solid var(--orange);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 4px 2px rgba(var(--orange-rgb), 0.4);
    animation: float 2s ease-in-out infinite;
    transition: background-color 150ms ease-in-out;

    .thunder-icon {
        --color: var(--orange);

        position: absolute;
        width: 16px;
        height: 16px;
        left: calc(50% - 52px);
    }

    .time {
        flex: 1;
        text-align: center;
    }

    .close-button {
        position: absolute;
        right: 2px;
    }

    &.connected {
        top: initial;
        min-width: 100px;
        padding: 0 8px 0 6px;
        justify-content: initial;
        bottom: 12px;
        width: initial;
        animation: initial;

        .thunder-icon {
            position: initial;
        }
    }

    &:hover {
        background-color: #66461399;
    }
}

.quick-auth-tooltip-dialog.quick-auth-tooltip-dialog {
    width: 640px;

    .dialog-content {
        margin: 16px 0;
        line-height: 24px;
        color: var(--cream-dark);
    }
}

@media only screen and (max-width: 1024px) {
    .quick-auth-action {
        top: calc(52px +  var(--test-env-bar-height));


        &.connected {
            top: initial;
            bottom: calc(var(--footer-height) + 12px);
        }
    }
}

@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(4px);
    }
}

