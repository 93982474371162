.buttons-group {
    display: flex;

    & > .button {
        min-width: initial;
        flex: 1;

        &:not(:first-of-type) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 1px solid rgba(var(--black-rgb), 0.4);
        }

        &:not(:last-of-type) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}