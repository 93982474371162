.network-selector {
    width: 100%;

    .network-logo {
        max-width: 22px;
        max-height: 22px;
        border-radius: 11px;
        margin-right: 8px;
        object-fit: cover;
    }

    .network-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .network-address {
        margin-left: 8px;
    }

    .network-select {
        width: 0;

        .trigger-arrow {
            z-index: 10;
            padding: 8px 0;
        }
    }

    .network-wallet-select {
        flex: initial;

        &.small {
            padding: 8px;
            width: initial;

            .space {
                display: none;
            }

            .trigger-arrow svg {
                margin-left: 6px;
            }
        }
    }

    .common-networks {
        position: sticky;
        top: var(--control-height);
        border-bottom: 1px solid var(--border-color-dark);
        padding: var(--control-padding);
        background: var(--black-dark);
        z-index: 10;
        overflow: scroll;
        display: flex;
        -ms-overflow-style: none;
        scrollbar-width: none;
        align-items: center;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.25);

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &.small .network-logo {
        max-width: 20px;
        max-height: 20px;
        border-radius: 10px;
        margin-right: 8px;
    }

    &.have-address-input .network-select {
        width: initial;
        flex: initial;
        max-width: 50%;
    }

    .network-option.network-option, .empty-search-results-label {
        min-width: 320px;
    }
}

.hub-options-modal {
    opacity: 0 !important;
}

