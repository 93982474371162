$dialog-width: 480px;

.sponsorship-revoke-dialog.sponsorship-revoke-dialog {
    width: $dialog-width;

    .dialog-content {
        margin: 16px 0 24px;
        color: var(--cream-dark);
    }

    .revoke-button {
        --background-color-inverse: var(--red-dark);
        --button-text-color: var(--cream);
        --button-color-hover: rgba(var(--red-dark-rgb), 0.7);
        --button-color-active: rgba(var(--red-dark-rgb), 0.5);
        --button-color-disable: rgba(var(--red-rgb), 0.1);
    }
}
