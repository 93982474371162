.claimable-transfers-link {
    text-align: center;
    border-bottom: 1px solid var(--border-color-dark);
    display: block;
    margin-top: -8px;
    margin-bottom: 8px;
}

.balances-list-container {
    padding-bottom: 40px;
}

.visible-networks-action {
    margin-top: -40px;
    background-color: rgba(var(--black-rgb), 0.8);
    font-weight: bold;
    justify-content: center;
}

.endpoints-warning {
    margin: 16px 8px 0;
    padding: 8px 12px 8px 12px;

    ul.list {
        margin-top: -2px;
    }

    .alert-icon-container {
        display: none;
    }
}

.credit-payment-dialog.credit-payment-dialog {
    width: 480px;
    height: 766px;
    display: flex;
    flex-direction: column;

    .dialog-content {
        flex: 1;
        position: relative;
        margin: var(--page-padding) calc(var(--page-padding) * -1) calc(var(--page-padding) * -1);
        display: flex;
        align-items: center;
        justify-content: center;

        .credit-payment-widget {
            width: 100%;
            height: 100%;
            display: block;
            border: none;
            z-index: 1;
        }

        .dialog-spinner {
            position: absolute;
            z-index: 0;
        }
    }
}

@media only screen and (min-width: 721px) {
    .credit-payment-dialog.credit-payment-dialog {
        --page-padding: 16px;
    }
}

