.pool-positions-container {
    display: flex;

    .pool-position-section-container {
        margin-top: var(--page-padding);
        flex: 1;

        .pool-position-header {
            align-items: center;
            display: flex;
            height: 40px;
            margin-bottom: 8px;
        }

        .pool-position-section {
            display: flex;
            flex-wrap: wrap;
            position: relative;

            .position-liquidity-part {
                flex: 1;
                display: flex;
                flex-direction: column;

                .pool-position-section-title {
                    color: var(--text-color-secondary);
                }
            }

            .position-actions-part {
                margin-left: 24px;

                .pool-position-action {
                    &.highlight {
                        animation: float 1.5s ease-in-out infinite;
                        box-shadow: 0 0 8px 4px rgba(var(--cream-rgb), 0.4);
                    }

                    &:not(:first-of-type) {
                        margin-left: 16px;
                    }
                }
            }

            .bottom-info-texts {
                flex: 1;
                min-width: 100%;
                white-space: nowrap;
                line-height: 18px;

                .pool-position-apr-info {
                    margin-left: 8px;
                    float: right;

                    .apr-value {
                        color: var(--light-green);
                        font-weight: bold;

                        .apr-spinner {
                            display: inline-block;
                        }
                    }
                }
            }

            &:not(:last-of-type) {
                margin-bottom: var(--page-large-padding);
            }
        }

        &:not(:last-of-type) {
            margin-right: 24px;
        }
    }
}

@media only screen and (max-width: 1280px) {
    .pool-positions-container {
        flex-direction: column;

        .pool-position-section-container:not(:last-of-type) {
            margin-right: 0;
        }
    }
}


@media only screen and (max-width: 720px) {
    .pool-positions-container .pool-position-section-container {

        .pool-position-section {
            flex-direction: column;

            .position-liquidity-part {
                align-items: center;
            }

            .position-actions-part {
                margin-top: 16px;
                display: flex;
                margin-left: 0;

                .pool-position-action {
                    flex: 1;
                }
            }

            .pool-position-apr-info {
                margin-left: 0;
                width: 100%;
                margin-top: 16px;
                text-align: center;
                white-space: initial;
            }
        }
    }
}

@keyframes float {
    0%, 100% {
        transform: scale(1) rotateX(0deg);
    }
    50% {
        transform: scale(1.1) rotateX(8deg);
    }
}
