.accounts-section {

    .controls-row {
        align-items: start;
    }

    .accounts-controls.accounts-controls {
        margin-right: 40px;
        flex: 1.5;
    }

    .file-uploader-container {
        margin-bottom: 0;

        .file-uploader {
            height: 224px;
            justify-content: center;
        }
    }

    .edit-accounts-dialog-button {
        margin-top: 16px;
        width: 100%;
    }

    .accounts-limitation-description {
        margin-top: 12px;
    }

    .alert-edit-accounts-dialog-button {
        display: block;
        margin-top: 12px;
    }

    .accounts-json-example {
        margin-bottom: 0;
        margin-top: -44px;

        .object-key-val > span > span .icon-container {
            &, & + span .array-key, & + span .array-key + span {
                display: none !important;
            }
        }
    }

    .accounts-summary {
        justify-content: space-between;
        margin-top: 24px;
        border-top: 1px solid var(--border-color);
        padding-top: 24px;

        .statistics-card.section {
            margin-bottom: 0;
        }
    }

    .total-supply-alert {
        margin-top: 24px;
    }
}

@media only screen and (max-width: 1024px) {
    .accounts-section {
        .controls-row {
            flex-direction: column;
        }

        .accounts-controls.accounts-controls {
            margin-right: 0;
            margin-bottom: 16px;
            padding-bottom: 24px;
            width: 100%;
            border-bottom: 1px solid var(--border-color);
        }

        .file-uploader-container {
            width: 100%;
        }

        .accounts-json-example {
            margin-top: 0;

            .object-key-val > span > span .icon-container {
                display: inline-block !important;
            }
        }

        .accounts-summary .statistics-card.section {
            margin-bottom: 24px;
        }
    }
}

@media only screen and (max-width: 720px) {
    .accounts-section {
        .accounts-json-example {
            font-size: 12px;
        }

        .total-supply-alert {
            margin-top: 0;
        }
    }
}

