.select-trigger {
    --trigger-arrow-size: 10px;

    &.horizontally-baseline {
        align-items: center;
    }

    .trigger-arrow svg {
        transition: transform 150ms ease-in-out;
        max-width: var(--trigger-arrow-size);
        max-height: var(--trigger-arrow-size);
        min-width: var(--trigger-arrow-size);
        min-height: var(--trigger-arrow-size);
        margin-left: 10px;

        path, g {
            fill: var(--control-text-color);
        }
    }

    .more-label {
        font-weight: 600;
        color: var(--text-color-secondary);
        white-space: nowrap;
    }

    &.selected:not(.disabled) {
        color: var(--text-color);
    }

    &.large {
        --trigger-arrow-size: 16px;
    }

    &.open .trigger-arrow svg {
        transform: rotate(180deg);
    }
}

.options-loader {
    width: 100%;
    padding: 12px 16px;

    .spinner {
        margin: 0 auto;
    }
}

.options-modal {
    .search-input {
        --search-icon-size: 20px;

        border-width: 0 0 1px 0;
        border-radius: 0;
        position: sticky;
        top: 0;
        background: var(--black-dark);
        z-index: 10;
    }

    .empty-search-results-label {
        padding: var(--control-padding);
        display: block;
        text-align: center;
        font-size: 14px;
        line-height: 24px;
        opacity: 0.6;
    }
}


