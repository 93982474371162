.bridge-history {
    height: 538px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 calc(var(--page-padding) * -1) calc(var(--page-padding) * -0.5);

    .table-container {
        width: 100%;
        flex: 1;
        overflow: scroll;
        margin-bottom: calc(var(--page-padding) * 0.5);
        border-bottom: 1px solid var(--border-color);

        .table {
            border-radius: 0;

            .bridge-transfer-row {
                .table-column {
                    width: 24%;

                    &.status-column, &.time-column {
                        width: 14%;
                    }
                }

                &.header .table-column {
                    border-radius: 0;
                }


                .network-logo, .asset-logo {
                    object-fit: cover;
                    max-width: 22px;
                    width: 22px;
                    max-height: 22px;
                    border-radius: 11px;
                    margin-right: 8px;
                }
            }
        }
    }

    .no-data {
        display: flex;
        height: 64px;
        align-items: center;
        justify-content: center;
        color: var(--text-color-secondary);
    }
}

@media only screen and (max-width: 720px) {
    .bridge-history {
        height: 474px;
    }
}
