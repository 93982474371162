.import-token-dialog.import-token-dialog {
    width: 400px;

    .dialog-content {
        margin: var(--page-padding) 0;

        .network-select, .wallet-select {
            width: 100%;
            margin: 8px 0 24px;
        }

        .network-logo {
            object-fit: cover;
            max-width: 22px;
            max-height: 22px;
            border-radius: 11px;
            margin-right: 12px;
        }

        .network-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .token-available-loader {
            margin: 0 auto;
        }
    }
}
