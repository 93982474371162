.input-container {
    --search-icon-size: 20px;

    display: flex;
    align-items: center;
    position: relative;

    .search-icon {
        margin-right: 8px;
    }

    .input {
        outline: none;
        border: none;
        width: 100%;
        padding: 0;
        background-color: transparent;
        color: var(--control-text-color);

        &::placeholder {
            color: var(--control-placeholder-text-color);
        }

        &::-webkit-search-cancel-button, &::-webkit-search-decoration, &::-webkit-search-results-button, &::-webkit-search-results-decoration {
            display: none;
        }

        &:not(.show-number-arrows) {
            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                display: none;
            }

            &[type=number] {
                appearance: textfield;
            }
        }
    }

    .search-icon {
        --color: var(--control-text-color);

        min-width: var(--search-icon-size);
        min-height: var(--search-icon-size);
        max-width: var(--search-icon-size);
        max-height: var(--search-icon-size);
    }

    .clear-button {
        --button-text-color: var(--control-text-color);
        --button-color: rgba(var(--black-dark-rgb), 0.7);
        --button-color-hover: rgba(var(--black-dark-rgb), 0.7);

        opacity: 0;
        position: absolute;
        right: 0;
    }

    &:not(.disabled) .input:not(:placeholder-shown) {
        --control-text-color: var(--text-color);

        & + .clear-button {
            opacity: 1;
        }
    }

    &.error {
        border-color: var(--red);
    }

    &.large {
        --search-icon-size: 28px;
    }

    &.small {
        --search-icon-size: 18px;
        --control-height: 32px;
        --control-padding: 6px 8px;

        .search-icon {
            margin-right: 6px;
        }
    }

    .empty-search-results-label {
        padding: var(--control-padding);
        display: block;
        text-align: center;
        font-size: 14px;
        line-height: 24px;
        opacity: 0.6;
    }

    &:not(.error) .error-label {
        color: var(--orange);
    }
}

.suggestion-list.options-modal.overlay-pane .menu-action-list {
    padding: 4px 0;

    .menu-action-list-item {
        border-bottom: none;
    }
}

