.scrolling-trade-pairs-container {
    background-color: var(--black-dark);
    display: flex;
    width: 100%;

    .scrolling-trade-pairs-carousel-container {
        flex: 1;
        overflow: hidden;

        .scrolling-trade-pairs-carousel {
            --animation-duration: 10;

            height: 24px;
            display: flex;
            width: fit-content;
            min-width: 100vw;
            align-items: center;
            padding: 0 136px 0 48px;
            animation: trade-pairs-scroll var(--animation-duration) linear infinite;
            animation-play-state: running;

            .statistics-change-container {
                font-size: 12px;
                flex-wrap: initial;
                margin-right: 16px;
                cursor: pointer;

                &:hover {
                    color: var(--light-blue);
                }
            }
        }

        &:hover .scrolling-trade-pairs-carousel {
            animation-play-state: paused;
        }
    }

    .social-links {
        height: 24px;
        margin-left: 12px;
        padding-right: var(--page-padding);
        border-left-color: var(--border-color);

        .social-link {
            width: 16px;
            margin-left: 12px;
        }
    }

    &:before {
        content: '';
        position: absolute;
        height: 4px;
        top: -4px;
        width: calc(100% - var(--page-padding));
        background-color: black;
    }
}

@media only screen and (max-width: 1024px) {
    .scrolling-trade-pairs-container {
        .social-links {
            display: none;
        }

        .scrolling-trade-pairs-carousel-container .scrolling-trade-pairs-carousel {
            padding: 0 48px;
        }
    }
}

@keyframes trade-pairs-scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-100% + 100vw));
    }
}
