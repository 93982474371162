.info-section {
    --value-color: var(--cream-dark);
    --value-hover-color: var(--cream);
    --value-disabled-color: rgba(var(--cream-very-dark-rgb), 0.4);

    .info-property {
        &:not(:last-of-type) {
            margin-bottom: 16px;
        }

        .info-property-value {
            margin-top: 0;

            &.text {
                height: initial;
                white-space: pre-wrap;
                margin-top: 6px;
                word-break: break-word;
            }
        }

        .info-property-value-content {
            padding: 0;
            font-size: 14px;
            color: var(--value-color);
            width: 100%;
            justify-content: flex-start;

            svg g, svg path {
                fill: var(--value-color);
            }

            &.disabled {
                --value-color: var(--value-disabled-color);
            }

            &:not(.disabled):hover {
                --value-color: var(--value-hover-color);
            }
        }
    }
}

