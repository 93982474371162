.pools-page .pools-controls {
    display: flex;
    align-items: center;

    .pools-search {
        margin-right: 16px;
    }
}

@media only screen and (max-width: 1024px) {
    .pools-page .pools-controls {

        .pools-search {
            margin-right: 8px;
        }

        .add-pool-button-text {
            display: none;
        }
    }
}

@media only screen and (max-width: 720px) {
    .pools-page .pool-list-header .pools-controls {
        width: 100%;

        .pools-search {
            flex: 1;
        }
    }
}
