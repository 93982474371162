.bridge-transfer-dialog.bridge-transfer-dialog {
    width: 1024px;

    .dialog-content {
        margin-top: var(--page-padding);
    }

    .transfer-property {
        display: flex;
        line-height: 28px;

        .property-label {
            max-width: 25%;
            min-width: 25%;
            color: var(--cream-dark);
            display: flex;
            align-items: center;
        }

        .property-value {
            max-width: 75%;
            min-width: 75%;
            min-height: 28px;
            display: flex;
            align-items: center;
        }

        &:not(:last-of-type) {
            margin-bottom: 16px;
        }
    }

    .external-link-property {
        cursor: pointer;

        .external-link-icon {
            --color: var(--cream-dark);

            max-width: 20px;
            max-height: 20px;
            margin-left: 4px;
        }

        &:hover {
            text-decoration: underline;

            .external-link-icon {
                --color: var(--text-color);
            }
        }
    }

    .internal-link {
        --link-color: var(--text-color);

        font-weight: 500;

        &:hover {
            text-decoration: underline;
        }
    }

    .date-property .property-value .time-left {
        margin-left: 8px;
    }

    .border {
        display: block;
        margin-top: var(--page-padding);
        padding-top: var(--page-padding);
        border-top: 1px solid var(--border-color-dark);
    }

    .network-address-property .property-value {
        white-space: nowrap;

        .name-address {
            white-space: nowrap;
            display: flex;
            align-items: center;

            .address {
                color: var(--text-color-secondary);
                margin-left: 6px;
                display: flex;
                align-items: center;

                .address-container {
                    padding: 0;
                }
            }
        }
    }

    .network-logo, .asset-logo {
        object-fit: cover;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        border-radius: 12px;
    }

    .fulfiller-address-property-value {
        color: var(--text-color);
    }
}

@media only screen and (max-width: 800px) {
    .bridge-transfer-dialog.bridge-transfer-dialog {

        .transfer-property {
            flex-direction: column;
            font-size: 13px;

            .property-label {
                max-width: initial;
                min-width: initial;
            }
        }

        .date-property .property-value {
            flex-direction: column;
            align-items: initial;
            line-height: 22px;

            .time-left {
                margin-left: 0;
            }
        }
    }
}
