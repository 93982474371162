.rollapp-status-badge {
    font-weight: 600;
    position: relative;

    .status-label {
        z-index: 1;
        line-height: 15px;
    }

    &:before {
        content: '';
        background-color: rgba(var(--black-rgb), 0.5);
        position: absolute;
        border-radius: var(--rounder-border-radius);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
