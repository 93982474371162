.alert-box {
    display: flex;
    align-items: center;
    background-color: var(--black-dark);
    padding: 8px;
    border-radius: 8px;
    line-height: 24px;
    justify-content: center;

    .alert-box-icon {
        width: 18px;
        height: 18px;
        margin-right: 8px;
    }
}
